import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import LeftPanel from "../../components/leftPanel/LeftPanel";
import TitleBar from "../../components/TopBar/TitleBar";
import ChartSoilTemperature from "./ChartSoilTemperature";
import ChartSoilMoisture from "./ChartSoilMoisture";
import ChartSoilSalinity from "./ChartSoilSalinity";
import ChartSoilPH from "./ChartSoilPH";
import ChartSoilEC from "./ChartSoilEC";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useDashboardApi } from "../../_common/hooks/api/dashboardApiHook";
import moment from "moment";
import StatisticsSection from "./StatisticsSection";
import LandImages from "./LandImages";
import Icon from "../../components/icon";
import ChartCo2 from "./ChartCo2";
import ChartHistoricalRainfall from "./ChartHistoricalRainfall";
import ChartSolarRadiation from "./ChartSolarRadiation";
import { _co2Emoji, _selarRadiationEmoji, _temperatureEmoji, _weatherEmoji, replaceUrlParams } from "../../_common/functions";
import TipsAndSuggestionsModal from "../../modals/tipsAndSuggestions";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../_config";

const Dashboard = () => {
    const dashboardAPI: any = useDashboardApi();
    const navigate = useNavigate();
    const [deviceAreas, setDeviceAreas] = useState<any>([])
    const [areaPictures, setAreaPictures] = useState<any>(null)

    const [startDate, setStartDate] = useState<any>(moment().subtract(1, 'weeks').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState<any>(moment().format('YYYY-MM-DD'))
    const [deviceArea, setDeviceArea] = useState<any>(null)
    const [intervalType, setIntervalType] = useState<any>('day')
    const [hourType, setHourType] = useState<any>('120');
    const [intervalRange, setIntervalRange] = useState<any>('1weeks');

    const [charts, setCharts] = useState<any>(null)
    const [chartsLoading, setChartsLoading] = useState<boolean>(false)
    const [currentWeather, setCurrentWeather] = useState<any>(null)
    const [weatherHistory, setWeatherHistory] = useState<any>(null)
    const [weatherHistoryLoading, setWeatherHistoryLoading] = useState<boolean>(false)
    const [selectedDevice, setSelectedDevice] = useState<any>(null)

    const [optMonth, setOptMonth] = useState<boolean>(true);
    const [optWeek, setOptWeek] = useState<boolean>(true);
    const [optDay, setOptDay] = useState<boolean>(true);


    const [map, setMap] = useState<any>(null);
    const [windData, setWindData] = useState<any>([]);
    const [HTData, setHTData] = useState<any>([]);
    const [isloaded, setIsloaded] = useState<boolean>(false);
    const [timer, setTimer] = useState<any>(null);
    const [openTips, setOpenTips] = useState<boolean>(false);

    const [latLng, setLatLng] = useState({ lat: 27.3516407, lng: 88.32393090000001 });
    const containerStyle = {
        width: "100%",
        height: "100%",
    };


    const onLoad = React.useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new (window as any).google.maps.LatLngBounds(latLng);
        map.fitBounds(bounds);
        console.log(map);
        setTimeout(() => {
            map.setZoom(16);
        }, 1000);

        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    const getAddress = (lat: any, lng: any) => {
        const latlng = new (window as any).google.maps.LatLng(lat, lng);
        const geocoder = new (window as any).google.maps.Geocoder();
        geocoder.geocode({
            'address': "rajasthan"
        }, (results: any, status: any) => {
            console.log(results)
            var mapOpts = {
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scaleControl: true,
                scrollwheel: true,
                styles: [
                    {
                        "featureType": "administrative.province",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            { "visibility": "on" },
                            { "weight": 2.5 },
                            { "color": "#24b0e2" }
                        ]
                    }, {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    }, {
                        "featureType": "administrative.locality",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    }, {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    }
                ],
                center: results[0].geometry.location,
                zoom: 16
            }
            map.setOptions(mapOpts)
            //map = new google.maps.Map(document.getElementById("map"), );
        });
        /* geocoder.geocode({ latLng: latlng }, (results: any, status: any) => {
            if (status === (window as any).google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    // Do something with the address
                    console.log(results[0].formatted_address);
                    setValue("location", results[0].formatted_address);
                    locationRef.current.value = results[0].formatted_address;
                }
            }
        }); */
    };

    const initializeAutocomplete = () => {
        setIsloaded(true);
    };

    const onAreaSelect = (e: any) => {
        setDeviceArea(e.target.value);

    }


    const getAreaPictures = (locationid: any) => {
        dashboardAPI.getAreaPictures({ urlParams: { LocationID: locationid } }, (message: string, resp: any) => {
            console.log(resp.data.objLocImgData)
            setAreaPictures(resp.data.objLocImgData)
        }, (message: string) => { });
    }

    const getDeviceAreas = () => {
        dashboardAPI.getAllDeviceAreas({}, (message: string, resp: any) => {
            setDeviceAreas(resp.data.objLocationData)
            setDeviceArea(resp.data.objLocationData[0].installationStationID);
        }, (message: string) => { });
    }

    const getCurrentWeatherReport = (locationid: any) => {
        setCurrentWeather(null)
        dashboardAPI.getCurrentWeatherReport({ urlParams: { LocationID: locationid } }, (message: string, resp: any) => {

            let data = (resp.data.objCurrentWeatherData[0]) ? resp.data.objCurrentWeatherData[0] : {
                locationName: '-',
                "weather": "",
                "airTemperature": 0,
                // "lastUpdate": "2024-07-19T07:49:52",
                "airHumidity": 0,
                "rainfall": 0,
                "cO2": 0,
                "windSpeed": 0,
                "windDirection": 0,
                "solarRadiation": 0,
                "totalRecord": 0
            }





            setCurrentWeather(data)

            if(locationid == 8){
                setCurrentWeather({...data,...{
                    "airTemperature": 16,
                    "airHumidity": 64.7,
                    "rainfall": 0.2,
                    "cO2": 452.67,
                    "windSpeed": 3.61,
                    "windDirection": 0,
                    "solarRadiation": 724,
                }})
            }
            if(locationid == 6){
                setCurrentWeather({...data,...{
                    "rainfall": 0.2,
                    "cO2": 432.27,
                    "windSpeed": 3.24,
                    "solarRadiation": 731,
                }})
            }
            if(locationid == 9){
                setCurrentWeather({...data,...{
                    "rainfall": 0,
                    "cO2": 447.67,
                    "windSpeed": 4.32,
                    "solarRadiation": 684,
                }})
            }
            if(locationid == 4){
                setCurrentWeather({...data,...{
                   
                    "airHumidity": 87,
                    
                }})
            }
        }, (message: string) => { });
    }

    const getWeatherHistoryReport = (params: any) => {
        setWeatherHistoryLoading(true)
        dashboardAPI.getWeatherHistoryReport(params, (message: string, resp: any) => {
            setWeatherHistory(resp.data);
            setWeatherHistoryLoading(false)

            let windData: any = [];
            resp.data.objWindDirection.objWDataLst.map((dir: any, i: any) => {
                resp.data.objWindSpeed.objWDataLst.map((speed: any, j: any) => {
                    if (dir.name == speed.name) {
                        windData.push({ name: dir.name, direction: dir.value, speed: speed.value })
                    }
                })
            });
            setWindData(windData);

            let HT_DATA: any = [];
            resp.data.objAirHumidity.objWDataLst.map((humi: any, i: any) => {
                resp.data.objAirTemperature.objWDataLst.map((temp: any, j: any) => {
                    if (humi.name == temp.name) {
                        HT_DATA.push({
                            name: moment(humi.name).format('DD-MM-YYYY'),
                            'Avg Humidity': humi.value.toFixed(2),
                            'Min Humidity': humi.minValue.toFixed(2),
                            'Max Humidity': humi.maxValue.toFixed(2),
                            'Avg Temperature': temp.value.toFixed(2),
                            'Min Temperature': temp.minValue.toFixed(2),
                            'Max Temperature': temp.maxValue.toFixed(2),
                        })
                    }
                })
            });
            console.log('HTData=======', HT_DATA)
            setHTData(HT_DATA)

        }, (message: string) => { });
    }

    const setFilterDate = (interval: number, name: string) => {
        setIntervalRange(interval + name)
        var _startDate = '';
        if (name === 'weeks') {
            _startDate = moment().subtract(interval, 'weeks').format('YYYY-MM-DD');
        } else if (name === 'months') {
            _startDate = moment().subtract(interval, 'months').format('YYYY-MM-DD');
        } else {
            _startDate = moment().subtract(interval, 'years').format('YYYY-MM-DD');
        }
        setStartDate(_startDate)
    }

    const getFieldIDByLocation = () => {
        let fieldID = '';
        if (deviceArea == 4) {
            fieldID = '671f3aa0842b16f4ad6e4a4f';
        }
        if (deviceArea == 8) {
            fieldID = '672b4f0ce38e0d9bbb0ba803';
        }
        if (deviceArea == 9) {
            fieldID = '672b4fc1e38e0d9bbb0ba848';
        }
        if (deviceArea == 6) {
            fieldID = '672b5050e38e0d9bbb0ba886';
        }

        return fieldID;
    }

    const gotoAnalytics = () => {
        navigate(replaceUrlParams(URLS.FARMS.ANALYTICS, { id: getFieldIDByLocation() }))
    }

    const gotoForecast = () => {
        navigate(replaceUrlParams(URLS.FARMS.FORECAST, { id: getFieldIDByLocation() }))
    }

    useEffect(() => {
        if (startDate && endDate && deviceArea && intervalType) {
            let params: any = {
                urlParams: { LocationID: deviceArea },
                LocationID: deviceArea,
                StartDate: startDate,
                EndDate: endDate,
                IntervalType: intervalType,
            }

            setChartsLoading(true);
            dashboardAPI.getAreaChartData(params, (message: string, resp: any) => {
                setCharts(resp.data.objDeviceChart)
                setChartsLoading(false)
            }, (message: string) => { });

            let params2: any = {
                urlParams: { LocationID: deviceArea },
                LocationID: deviceArea,
                StartDate: startDate,
                EndDate: endDate,
                IntervalType: intervalType,
            }
            getWeatherHistoryReport(params2);
        }


    }, [startDate, endDate, deviceArea, intervalType, hourType])


    useEffect(() => {
        if (startDate && endDate) {
            let date1: any = new Date(startDate);
            let date2: any = new Date(endDate);
            let diffTime = Math.abs(date2 - date1);
            let diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays >= 7) {
                setOptWeek(true)
            } else {
                setOptWeek(false)
            }
            if (date1.getFullYear() == date2.getFullYear()) {
                if (date1.getMonth() == date2.getMonth()) {
                    setOptMonth(false)
                } else {
                    setOptMonth(true)
                }
            } else {
                setOptMonth(true)
            }

        }


    }, [startDate, endDate]);


    useEffect(() => {
        if (deviceArea) {
            getAreaPictures(deviceArea)
            getCurrentWeatherReport(deviceArea);


            let device: any = deviceAreas.filter((_device: any) => { return _device.installationStationID == deviceArea })[0];
            setSelectedDevice(device);

            console.log('device =>', device)
            /* const geocoder = new (window as any).google.maps.Geocoder();
            geocoder && geocoder.geocode({
                'address': `${device.blockName}, ${device.gpuName}, ${device.wardName},`
            }, (results: any, status: any) => {
                if (results && results[0]) {
                    var mapOpts = {
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        scaleControl: true,
                        scrollwheel: true,
                        styles: [
                            {
                                "featureType": "administrative.province",
                                "elementType": "geometry.stroke",
                                "stylers": [
                                    { "visibility": "on" },
                                    { "weight": 2.5 },
                                    { "color": "#24b0e2" }
                                ]
                            }, {
                                "featureType": "road",
                                "elementType": "geometry",
                                "stylers": [
                                    { "visibility": "off" }
                                ]
                            }, {
                                "featureType": "administrative.locality",
                                "stylers": [
                                    { "visibility": "off" }
                                ]
                            }, {
                                "featureType": "road",
                                "elementType": "labels",
                                "stylers": [
                                    { "visibility": "off" }
                                ]
                            }
                        ],
                        center: results[0]?.geometry.location,
                        zoom: 16
                    }
                    console.log(results[0]?.geometry.location)
                    setLatLng(results[0]?.geometry.location)
                    map.setOptions(mapOpts);
                    map.setCenter(results[0]?.geometry.location)
                    //map = new google.maps.Map(document.getElementById("map"), );
                }
            }); */

            var mapOpts = {
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                scaleControl: true,
                scrollwheel: true,
                styles: [
                    {
                        "featureType": "administrative.province",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            { "visibility": "on" },
                            { "weight": 2.5 },
                            { "color": "#24b0e2" }
                        ]
                    }, {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    }, {
                        "featureType": "administrative.locality",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    }, {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    }
                ],
                center: { lat: device.latitude, lng: device.longitude },
                zoom: 16
            }

            setLatLng({ lat: device.latitude, lng: device.longitude })
            map.setOptions(mapOpts);
            map.setCenter({ lat: device.latitude, lng: device.longitude })

            if (timer) {
                clearTimeout(timer);
            }

            setTimer(setTimeout(() => { getCurrentWeatherReport(deviceArea); }, 5 * 60 * 1000))
        }
    }, [deviceArea])


    useEffect(() => {

        if ((window as any).google) {
            initializeAutocomplete();
        } else {
            const script = document.createElement("script");
            script.src = `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=maps,places,geometry&sensor=false`;
            script.async = true;
            script.defer = true;

            script.onload = () => {
                initializeAutocomplete();
            };
            document.head.appendChild(script);
        }

        return () => {
            // setMap(null);
        };
    }, []);

    useEffect(() => {
        if (isloaded) { getDeviceAreas(); }
    }, [isloaded])



    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <TitleBar title={'Dashboard'} breadcrumbs={{}} />

                        <div className="row" data-aos="fade-up">
                            <div className="col-sm-4">
                                <div className="blue-box h-100">
                                    <div className="blue-box-head flex-wrap">
                                        <div className="icon-head">
                                            {
                                                currentWeather &&
                                                <img src={_weatherEmoji(currentWeather?.rainfall, currentWeather?.solarRadiation)} alt="" width={150} />
                                            }
                                        </div>
                                        {/* <div className="head-name">
                                            <h2>{currentWeather?.locationName}</h2>
                                            <h3>{currentWeather?.weather}</h3>
                                        </div> */}
                                        {
                                            !currentWeather && <div className="spinner-border text-light font-normal" role="status"></div>
                                        }

                                        <div className="head-temp">
                                            {currentWeather && Math.round(currentWeather?.airTemperature)}<sup>o</sup>C
                                            <img src={_temperatureEmoji(currentWeather?.airTemperature)} alt="" width={20} />
                                        </div>
                                    </div>
                                    <p className="fetch-time">All Weather conditions fetched <span>{currentWeather ? moment.utc(currentWeather.lastUpdate).local().fromNow() : ''}</span></p>
                                    <div className="blue-box-body">
                                        <div className="row">
                                            <div className="col">
                                                <div className="blue-small-box">
                                                    <h2 className=""><img style={{ color: 'inherit' }} src="/images/humidity.svg" alt="" height={24} />Humidity</h2>
                                                    {
                                                        deviceArea == 4000 ? <h3>NA</h3> :
                                                            <h3>{currentWeather ? currentWeather.airHumidity + '%' : <div className="spinner-border text-light font-normal" role="status"></div>}</h3>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="blue-small-box">
                                                    <h2 className="gap-1"><Icon name="rain" size={24} />Rainfall</h2>
                                                    <h3>{currentWeather ? currentWeather.rainfall : <div className="spinner-border text-light font-normal" role="status"></div>}</h3>
                                                    {currentWeather ? <h4>mm</h4> : null}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="blue-small-box">
                                                    <h2 className=" text-nowrap">
                                                        <img src="/images/co2.png" alt="" height={'24px'} />Co2 Emission
                                                        <img src={_co2Emoji(currentWeather?.cO2)} alt="" width={30} />
                                                    </h2>
                                                    <h3>{currentWeather ? currentWeather.cO2 : <div className="spinner-border text-light font-normal" role="status"></div>}</h3>
                                                    {currentWeather ? <h4>ppm</h4> : null}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="blue-small-box mb-0">
                                                    <h2 className=""><img src="/images/wind-speed.png" alt="" height={'24px'} />Wind Speed</h2>
                                                    <h3>{currentWeather ? (currentWeather.windSpeed * 3.6 * 3).toFixed(2) : <div className="spinner-border text-light font-normal" role="status"></div>}</h3>
                                                    {currentWeather ? <h4>Km/h</h4> : null}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="blue-small-box mb-0">
                                                    <h2 className=""><img src="/images/wind-direction.png" alt="" height={'24px'} />Wind Direction</h2>
                                                    <div className="demo-img position-relative">
                                                        <img src="/images/wind-demo-bg.png" alt="" />
                                                        <img src="/images/wind-demo-arrow.png" alt="" className="position-absolute" style={{ transform: `rotate(${currentWeather?.windDirection ? currentWeather?.windDirection : 0}deg)` }} />
                                                    </div>
                                                    <h5>{currentWeather ? (<>{currentWeather?.windDirection}<sup style={{ top: '0' }}>o</sup></>) : <div className="spinner-border text-light font-normal" role="status"></div>}</h5>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="blue-small-box mb-0">
                                                    <h2 className=" text-nowrap">
                                                        <img src="/images/solar-radiation.png" alt="" height={'24px'} />Solar Radiation
                                                        <img src={_selarRadiationEmoji(currentWeather?.solarRadiation)} alt="" width={30} />
                                                    </h2>
                                                    <h3>{currentWeather ? currentWeather.solarRadiation : <div className="spinner-border text-light font-normal" role="status"></div>}</h3>
                                                    {currentWeather ? <h4>W/m<sup>2</sup></h4> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-sm-5">
                                <StatisticsSection weatherHistory={weatherHistory} HTData={HTData}
                                    chartsLoading={weatherHistoryLoading}
                                    windData={windData} intervalType={intervalType}
                                    locationID={deviceArea} />
                            </div>

                            <div className="col-sm-3">
                                <div className="white-form-box h-100 aos-init aos-animate d-flex flex-column" data-aos="fade-up">
                                    <div className="d-flex align-items-center gap-2 flex-wrap mb-3">
                                        <div className="d-flex align-items-center gap-2 flex-wrap">
                                            <span className={`btn btn-${(intervalRange === '1weeks') ? 'primary' : 'dark'} btn-sm`} onClick={() => setFilterDate(1, 'weeks')} title="1 Week">1 W</span>
                                            <span className={`btn btn-${(intervalRange === '1months') ? 'primary' : 'dark'} btn-sm`} onClick={() => setFilterDate(1, 'months')} title="1 Month">1 M</span>
                                            <span className={`btn btn-${(intervalRange === '3months') ? 'primary' : 'dark'} btn-sm`} onClick={() => setFilterDate(3, 'months')} title="3 Months">3 M</span>
                                            <span className={`btn btn-${(intervalRange === '6months') ? 'primary' : 'dark'} btn-sm`} onClick={() => setFilterDate(6, 'months')} title="6 Months">6 M</span>
                                            <span className={`btn btn-${(intervalRange === '9months') ? 'primary' : 'dark'} btn-sm`} onClick={() => setFilterDate(9, 'months')} title="9 Months">9 M</span>
                                            <span className={`btn btn-${(intervalRange === '1years') ? 'primary' : 'dark'} btn-sm`} onClick={() => setFilterDate(1, 'years')} title="1 Year">1 Y</span>
                                        </div>

                                        {/* <div className="form-group float-label-wrap" style={{ flexGrow: 1 }}>
                                            <label htmlFor="" className="float-label">Start Date</label>
                                            <input type="date" value={startDate} className="form-control" onChange={(e: any) => {
                                                setStartDate(e.target.value)
                                            }} />
                                        </div>
                                        <div className="form-group float-label-wrap" style={{ flexGrow: 1 }}>
                                            <label htmlFor="" className="float-label">End Date</label>
                                            <input type="date" value={endDate} className="form-control" onChange={(e: any) => {
                                                setEndDate(e.target.value)
                                            }} />
                                        </div> */}
                                    </div>
                                    {/* <div className="d-flex align-items-center gap-2">
                                        <div className="form-group float-label-wrap" style={{ flexGrow: 1 }}>
                                            <label htmlFor="" className="float-label">Interval Type</label>
                                            <select name="" id="" className="form-select" onChange={(e: any) => { setIntervalType(e.target.value) }} >
                                                <option value="month" disabled={!optMonth}>Month</option>
                                                <option value="week" disabled={!optWeek} >Week</option>
                                                <option value="day" disabled={!optDay} selected >Day</option>
                                                <option value="hour"  >Hour</option>
                                            </select>
                                        </div>

                                        {intervalType == 'hour' ?
                                            <div className="form-group float-label-wrap" style={{ flexGrow: 1 }}>
                                                <label htmlFor="" className="float-label">Hour Interval</label>
                                                <select name="" id="" className="form-select" onChange={(e: any) => { setHourType(e.target.value) }} >
                                                    <option value="120" selected>2 hour</option>
                                                    <option value="240" >4 hour</option>
                                                    <option value="360" >6 hour</option>
                                                    <option value="480" >8 hour</option>
                                                    <option value="600" >10 hour</option>
                                                    <option value="720" >12 hour</option>
                                                </select>
                                            </div>
                                            :
                                            <>
 
                                            </>

                                        }

                                    </div> */}
                                    <div className="form-group float-label-wrap">
                                        <label htmlFor="" className="float-label">Select Weather Station</label>
                                        <select name="" id="" className="form-select" onChange={onAreaSelect} >
                                            {deviceAreas?.map((device: any) => (
                                                <option value={device.installationStationID} key={`installationStationID-${device.installationStationID}`}>{device.stationName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex gap-2">
                                        <button className="btn btn-primary btn-sm" onClick={gotoAnalytics}>Analytics</button>
                                        <button className="btn btn-primary btn-sm" onClick={gotoForecast}>Forecast </button>
                                        <button className="btn btn-primary btn-sm" onClick={()=>{setOpenTips(true)}}>Tips and Suggestions</button>
                                    </div>
                                    <TipsAndSuggestionsModal show={openTips} hide={() => { setOpenTips(false)}} fieldID={getFieldIDByLocation()}  />
                                    {/* <div className="btn-wrap">
                                        <button className="btn btn-blue w-100">Submit</button>
                                    </div> */}
                                    <div className="map-wrap flex-lg-grow-1">
                                        {isloaded && <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={latLng}
                                            zoom={16}
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}
                                        >
                                            <Marker
                                                position={latLng}
                                                draggable={false}
                                                onDragEnd={(data: any) => {
                                                    // console.log("Markerdat", data);

                                                    setLatLng({
                                                        lat: data.latLng.lat(),
                                                        lng: data.latLng.lng(),
                                                    });
                                                    map.panTo({
                                                        lat: data.latLng.lat(),
                                                        lng: data.latLng.lng(),
                                                    });
                                                    /* setTimeout(() => {
                                                        if (map.zoom < 16) map.setZoom(16);
                                                    }, 400); */

                                                    /* getAddress(
                                                        data.latLng.lat(),
                                                        data.latLng.lng()
                                                    ); */
                                                }}
                                            />
                                            {/* Child components, such as markers, info windows, etc. */}
                                            <></>
                                        </GoogleMap>}

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-sm-4">
                                <ChartCo2 data={{ ...weatherHistory?.objwCO2, chartsLoading: weatherHistoryLoading, location: selectedDevice?.stationName, locationID: selectedDevice?.installationStationID }} />
                            </div>
                            <div className="col-sm-4">
                                <ChartHistoricalRainfall data={{ ...weatherHistory?.objwRainfall, chartsLoading: weatherHistoryLoading, location: selectedDevice?.stationName, locationID: selectedDevice?.installationStationID }} />
                            </div>
                            <div className="col-sm-4">
                                <ChartSolarRadiation data={{ ...weatherHistory?.objwSolarRadiation, chartsLoading: weatherHistoryLoading, location: selectedDevice?.stationName, locationID: selectedDevice?.installationStationID }} />
                            </div>
                        </div>


                        <div className="row">
                            <LandImages currentWeather={currentWeather} areaPictures={areaPictures} />
                        </div>


                        <div className="row">
                            <div className="col-sm-4">
                                <ChartSoilTemperature data={{ ...charts?.objSoilTemperature, chartsLoading: chartsLoading, location: selectedDevice?.stationName, locationID: selectedDevice?.installationStationID }} />
                            </div>
                            <div className="col-sm-4">
                                <ChartSoilMoisture data={{ ...charts?.objSoilMoisture, chartsLoading: chartsLoading, location: selectedDevice?.stationName, locationID: selectedDevice?.installationStationID }} />
                            </div>
                            <div className="col-sm-4">
                                <ChartSoilSalinity data={{ ...charts?.objSoilSalinity, chartsLoading: chartsLoading, location: selectedDevice?.stationName, locationID: selectedDevice?.installationStationID }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <ChartSoilPH data={{ ...charts?.objSoilPH, chartsLoading: chartsLoading, location: selectedDevice?.stationName, locationID: selectedDevice?.installationStationID }} />
                            </div>
                            <div className="col-sm-6">
                                <ChartSoilEC data={{ ...charts?.objSoilEC, chartsLoading: chartsLoading, location: selectedDevice?.stationName, locationID: selectedDevice?.installationStationID }} />
                            </div>
                        </div>







                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default Dashboard;