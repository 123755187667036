import { API_BASE_URL, MAPAPI_BASE_URL } from "./site_urls";
export const STORAGE = "precision-farming-ui"
export const STORAGE_VERSION = "202407221509"

export const GRAPH_COLORS = {
    SOIL_EC: {PRIMARY: "#7c3aed", LOW: "#c4b5fd",  HIGH: "#a78bfa"},
    SOIL_PH: {PRIMARY: "#65a30d", LOW: "#bef264",  HIGH: "#a3e635"},
    SOIL_MOISTURE: {PRIMARY: "#0284c7", LOW: "#bae6fd",  HIGH: "#7dd3fc"},
    SOIL_TEMP: {PRIMARY: "#dc2626", LOW: "#fca5a5",  HIGH: "#f87171"},
    SOIL_SALINITY: {PRIMARY: "#475569", LOW: "#cbd5e1",  HIGH: "#94a3b8"},
    SOLAR_RADIATION: {PRIMARY: "#9333ea", LOW: "#d8b4fe",  HIGH: "#c084fc"},
    WIND_DIRECTION: {PRIMARY: "#475569", LOW: "#cbd5e1",  HIGH: "#94a3b8"},
    WIND_SPEED: {PRIMARY: "#475569", LOW: "#cbd5e1",  HIGH: "#94a3b8"},
    CO2: {PRIMARY: "#475569", LOW: "#cbd5e1",  HIGH: "#94a3b8"},
    RAIN: {PRIMARY: "#475569", LOW: "#cbd5e1",  HIGH: "#94a3b8"},
    HUMIDITY: {PRIMARY: "#475569", LOW: "#cbd5e1",  HIGH: "#94a3b8"},
    AIR_TEMP: {PRIMARY: "#475569", LOW: "#cbd5e1",  HIGH: "#94a3b8"},
    AIR_HUMIDITY: {PRIMARY: "#475569", LOW: "#cbd5e1",  HIGH: "#94a3b8"},
    NDVI: {PRIMARY: "#d97706", LOW: "#fcd34d",  HIGH: "#f59e0b"},
}


export const URLS = {
    LOGIN: "/login",
    LOGOUT: "/logout",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password/:tokenID",
    DASHBOARD: "/",
    EMPLOYEE_LIST: "/employees",
    EMPLOYEE_ADD: "/employee/add",
    EMPLOYEE_EDIT: "/employee/edit/:employeeID",
    EMPLOYEE_DETAILS: "/employee/:employeeID",
    EMPLOYEE_PERMISSION: "/employee/permission/:employeeID",
    PROFILE: "/profile",
    PROFILE_EDIT: "/profile/edit/:employeeID",

    FARMER_LIST: "/farmers",
    FARMER_EDIT: "/farmer-edit",
    FARMER_DETAILS: "/farmer/:farmerID",
    SENSORS: "/sensors/:farmID",

    FARMS: {
        LIST: '/farms',
        DETAILS: '/farm/:id',
        WEATHER: '/farm/weather/:id',
        ANALYTICS: '/farm/analytics/:id',
        FORECAST: '/farm/forecast/:id',
        ALERTS:'/alerts'
    },

    COLLATERAL: {
        CROP_CATEGORY: {
            LIST: "/crop-categories",
            ADD: "/crop-category/add",
            EDIT: "/crop-category/edit/:id",
        },

        CROP: {
            LIST: "/farming-methods",
            ADD: "/farming-method/add",
            DETAILS: "/farming-method/:id",
            EDIT: "/farming-method/edit/:id",
            CULTIVATION_METHODS_VIEW: "/farming-method/cultivation-methods/:id",
            CULTIVATION_METHODS_EDIT: "/farming-method/cultivation-methods/edit/:id",
        },

        CROP_PROTECTION_METHOD: {
            LIST: "/crop-protection-methods",
            ADD: "/crop-protection-method/add",
            EDIT: "/crop-protection-method/edit/:id",
            DETAILS: "/crop-protection-method/:id",
        }

    },



    TRAINING_GROUP: "/training-groups",
    TRAINING_GROUP_ADD: "/training-group/add",
    TRAINING_GROUP_EDIT: "/training-group/edit/:groupID",

    EVENTS_LIST: "/events",
    EVENTS_DETAILS: "/event/:eventID",
    EVENTS_ADD: "/event/add",
    EVENTS_EDIT: "/event/edit/:eventID",
    EVENT_ATTENDEE: "/event/:eventID/attendee",

    DEVICE_CATEGORY_UNIT: {
        LIST: '/device-units',
        ADD: '/device-unit/add',
        EDIT: '/device-unit/edit/:id',
    },

    DEVICE_CATEGORY: {
        LIST: '/device-categories',
        ADD: '/device-category/add',
        EDIT: '/device-category/edit/:id',
        DETAILS: '/device-category/:id',
    },

    DEVICE_LIST: "/device-management",
    IOT_INSTALLATION_STATIONS_LIST: "/iot-installation-stations",
    IOT_INSTALLATION_ADD: "/iot-installation-station/add",
    IOT_INSTALLATION_EDIT: "/iot-installation-station/edit/:deviceID",
    DEVICE_ADD: "/device-management/add",
    DEVICE_EDIT: "/device-management/edit/:deviceID",
    DEVICE_DETAILS: "/device-management/:id",



    REPORTS: {
        DRONE_IMAGERY: "/reports/drone-imagery",
        IOT_INSTALLATION_STATIONS: "/reports/iot-installation-stations",
        FARMERS_LIST_REPORT: "/reports/farmers-report",
        FARMS_LIST_REPORT: "/reports/farms-report",
        FARMERS_CONTACT_LISTS: "/reports/farmers-contact-lists",
        FARMERS_TRAINING_ATTENDANCE_LISTS: "/reports/farmers-training-attendance-report",
    },


    ROLE_MANAGEMENT_SETTINGS: "/role-management-settings",

    ROLE: {
        LIST: "/role-management",
        ADD: "/role-management/add",
        EDIT: "/role-management/edit/:id",
        PERMISSION: "/role-management/permission/:id",
    },
    SETTINGS: {
        EMAIL_SETTINGS: {
            LIST: "/settings/email",
            EDIT: "/settings/email/edit/:id",
        },
        MODEL: {
            PARAMS: "/model-params",
        },
        UPLOAD: "/upload",
    },

    NOTIFICATION: {
        MANUAL_NOTIFICATION: "/manual-notification",
        NOTIFICATION_DETAILS: "/notification-broadcast/:NotificationID",
        NOTIFICATION_BROADCAST: "/notification-broadcast",
        INVITE_FARMERS_LIST: "/invite-farmers-list",
        INVITE_FARMERS: "/invite-farmers",
    }
}
export const API_URL = {
    USER: {
        LOGIN: `${API_BASE_URL}/Login/token`,
        FORGET_PASSWORD: `${API_BASE_URL}/Login/ForgotPassword`,
        RESET_PASSWORD: `${API_BASE_URL}/Login/ForgotPasswordReset`,
        PASSWORD_RESET: `${API_BASE_URL}/Login/PasswordReset`,
        REFRESH_TOKEN: `${API_BASE_URL}/Login/RefreshToken`,
    },
    EMPLOYEE: {
        ADD_EMPLOYEE: `${API_BASE_URL}/Employee/Save`,
        UPDATE_EMPLOYEE: `${API_BASE_URL}/Employee/Update`,
        GET_EMPLOYEE: `${API_BASE_URL}/Employee/getAllEmployee`,
        GET_ACTIVE_EMPLOYEE: `${API_BASE_URL}/Employee/getActiveEmployee`,
        GET_EMPLOYEE_DETAILS: `${API_BASE_URL}/Employee/getEmployeeByID/:employeeID`,
        UPDATE_EMPLOYEE_STATUS: `${API_BASE_URL}/Employee/StatusUpdate`,
        DELETE_EMPLOYEE: `${API_BASE_URL}/Employee/AccountStatusUpdate`,


    },
    MASTER: {
        GET_DEPARTMENTS: `${API_BASE_URL}/Departments/getDepartmentsAll/:PageIndex/:PageSize`,
        GET_DESIGNATIONS: `${API_BASE_URL}/Designations/getDesignationsAll/:PageIndex/:PageSize`,
        GET_DISTRICTS: `${API_BASE_URL}/District/getDistrictAll/:PageIndex/:PageSize`,
        GET_GPUS: `${API_BASE_URL}/GPU/getGPUAll/:PageIndex/:PageSize`,
        GET_WARDS: `${API_BASE_URL}/Ward/getWardAll/:PageIndex/:PageSize`,
        GET_BLOCKS: `${API_BASE_URL}/Block/getBlockAll/:PageIndex/:PageSize`,


        ROLE: {
            LIST: `${API_BASE_URL}/Role/getRoleAll/:PageIndex/:PageSize`,
            DETAILS: `${API_BASE_URL}/Role/getRole/:RoleId`,
            SAVE: `${API_BASE_URL}/Role/Save`,
            UPDATE: `${API_BASE_URL}/Role/Update`,
            DELETE: `${API_BASE_URL}/Role/Delete`,

            PERMISSION: `${API_BASE_URL}/RolePermission/GetByRoleID/:fKRoleID`,
            PERMISSION_SAVE: `${API_BASE_URL}/RolePermission/Save`,
        },
        GET_ROLES: `${API_BASE_URL}/Role/getRoleAll/:PageIndex/:PageSize`,

        SETTINGS: {
            EMAIL_SETTINGS: {
                LIST: `${API_BASE_URL}/EmailSenderInformation/EmailSenderInformationGetAll`,
                DETAILS: `${API_BASE_URL}/EmailSenderInformation/EmailSenderInformationGetByID/:id`,
                UPDATE: `${API_BASE_URL}/EmailSenderInformation/Update`,
            },
        },
    },

    GROUP: {
        GET_GROUPS: `${API_BASE_URL}/Group/getGroupAll`,
        GET_GROUP_DETAILS: `${API_BASE_URL}/Group/getGroup/:GroupID`,
        SAVE_GROUP: `${API_BASE_URL}/Group/Save`,
        UPDATE_GROUP: `${API_BASE_URL}/Group/Update`,
        DELETE_GROUP: `${API_BASE_URL}/Group/Delete`,
        CHANGE_STATUS_GROUP: `${API_BASE_URL}/Group/StatusUpdate`,

    },

    DEVICE: {
        GET_DEVICES: `${API_BASE_URL}/Device/getDeviceAll`,
        GET_DEVICES_DETAILS: `${API_BASE_URL}/Device/getDevice/:DeviceId`,
        SAVE_DEVICES: `${API_BASE_URL}/Device/Save`,
        UPDATE_DEVICES: `${API_BASE_URL}/Device/Update`,
        DELETE_DEVICES: `${API_BASE_URL}/Device/Delete`,
        CHANGE_DEVICES_STATUS: `${API_BASE_URL}/Device/StatusUpdate`,
        DEVICES_WORKING_STATUS: `${API_BASE_URL}/Device/getAllDeviceWorkingStatus?PageIndex=1&PageSize=999`,


        GET_DEVICES_TEPLATE: `${API_BASE_URL}/DeviceTemplate/getDeviceTemplateAll/:PageIndex/:PageSize`,
        DEVICES_TEMPLATE_DETAILS: `${API_BASE_URL}/DeviceTemplate/getDeviceTemplate/:DeviceTemplateID`,
        DEVICES_TEMPLATE_SAVE: `${API_BASE_URL}/DeviceTemplate/Save`,
        DEVICES_TEMPLATE_UPDATE: `${API_BASE_URL}/DeviceTemplate/Update`,
        DEVICES_TEMPLATE_CHANGE_STATUS: `${API_BASE_URL}/DeviceTemplate/StatusUpdate`,
        DEVICES_TEMPLATE_DELETE: `${API_BASE_URL}/DeviceTemplate/Delete`,

        DEVICES_PARAM_TYPE_ALL: `${API_BASE_URL}/DeviceTemplate/getAllDeviceParamType`,
        DEVICES_UNIT_MASTER_ALL: `${API_BASE_URL}/DeviceTemplate/getAllUnitMaster`,
        DEVICES_PARAM_MASTER_ALL: `${API_BASE_URL}/DeviceTemplate/getAllDeviceParamMaster`,


        CATEGORY_UNIT: {
            LIST: `${API_BASE_URL}/DeviceTemplateParam/getDeviceTemplateParamAll/:PageIndex/:PageSize`,
            DETAILS: `${API_BASE_URL}/DeviceTemplateParam/getDeviceTemplateParam/:DeviceTemplateParamID`,
            SAVE: `${API_BASE_URL}/DeviceTemplateParam/Save`,
            UPDATE: `${API_BASE_URL}/DeviceTemplateParam/Update`,
            CHANGE_STATUS: `${API_BASE_URL}/DeviceTemplateParam/StatusUpdate`,
            DELETE: `${API_BASE_URL}/DeviceTemplateParam/Delete`,
        }
    },

    EVENT: {
        GET_EVENTS: `${API_BASE_URL}/Event/getEventAll`,
        GET_EVENTS_TYPES: `${API_BASE_URL}/Event/GetEventType`,
        GET_EVENTS_ORGANISER: `${API_BASE_URL}/Event/GetOrganizer`,
        GET_EVENT_DETAILS: `${API_BASE_URL}/Event/getEvent/:eventID`,
        SAVE_EVENT: `${API_BASE_URL}/Event/Save`,
        UPDATE_EVENT: `${API_BASE_URL}/Event/Update`,
        DELETE_EVENT: `${API_BASE_URL}/Event/Delete`,
        CHANGE_STATUS_EVENT: `${API_BASE_URL}/Event/StatusUpdate`,
        UPLOAD_EVENT_ATTENDEE: `${API_BASE_URL}/Event/EventFarmerImport`,
        GET_EVENT_FARMER_IMPORT_ALL: `${API_BASE_URL}/Event/getEventFarmerImportAll/:EventID`,
    },

    FARMER: {
        GET_FARMER: `${API_BASE_URL}/Farmer/getFarmerAll`,
        GET_FARMER_DETAILS: `${API_BASE_URL}/Farmer/getFarmer/:FarmerID`,
        CHANGE_STATUS_FARMER: `${API_BASE_URL}/Farmer/StatusUpdate`,
        IMPORT_FARMER: `${API_BASE_URL}/Farmer/FarmerImport`,

    },


    FARMS: {
        LIST: `${API_BASE_URL}/Farm/getFarmAll`,
        DETAILS: `${API_BASE_URL}/Farm/getFarm/:FarmID`,
        SAVE: `${API_BASE_URL}/Farm/Save`,
        UPDATE: `${API_BASE_URL}/Farm/Update`,
        CHANGE_STATUS: `${API_BASE_URL}/Farm/StatusUpdate`,
        DELETE: `${API_BASE_URL}/Farm/Delete`,
        IMPORT_FARMS: `${API_BASE_URL}/Farm/FarmImport`,


        FARMS_CROP_MAPPING_LIST: `${API_BASE_URL}/Farm/getFarmsCropMappingList`,
        FARMS_CROP_MAPPING_SAVE: `${API_BASE_URL}/Farm/FarmCropsMappingSave`,


        FIELDS_HISTORY: `${MAPAPI_BASE_URL}/fields/:field_or_farm_id/history`,
    },

    FORECAST: {
        SENSOR_RANGE_CHECKLIST: `${MAPAPI_BASE_URL}/sensor/range/checklists/:SensorId/predictive-checklist`,
    },

    DASHBOARD: {
        GET_DEVICE_AREAS: `${API_BASE_URL}/DeviceReport/GetLocationList`,
        GET_AREA_PICTURES: `${API_BASE_URL}/DeviceReport/GetLocationImageList/:LocationID`,
        GET_AREA_CHARTS: `${API_BASE_URL}/DeviceReport/GetDeviceChart/:LocationID`,
        GET_CURRENT_WEATHER_REPORT: `${API_BASE_URL}/DeviceReport/CurrentWeatherReport/:LocationID`,
        GET_WEATHER_HISTORY_REPORT: `${API_BASE_URL}/DeviceReport/WeatherDetailsHistoryReport/:LocationID`
    },

    DEVICE_INSTALLATION_STATION: {
        LIST: `${API_BASE_URL}/DeviceInstallationStation/getDeviceInstallationStationAll`,
        DETAILS: `${API_BASE_URL}/DeviceInstallationStation/getDeviceInstallationStation/:InstallationStationID`,
        SAVE: `${API_BASE_URL}/DeviceInstallationStation/Save`,
        UPDATE: `${API_BASE_URL}/DeviceInstallationStation/Update`,
        CHANGE_STATUS: `${API_BASE_URL}/DeviceInstallationStation/StatusUpdate`,
        DELETE: `${API_BASE_URL}/DeviceInstallationStation/Delete`,

        // /api/v{version}/DeviceInstallationStation/getUnAssignedStationDevice/{InstallationStationID}
        // /api/v{version}/DeviceInstallationStation/getAssignedStationDevice/{InstallationStationID}
        // /api/v{version}/DeviceInstallationStation/SaveStationDevice
        UN_ASSIGNED_STATION_DEVICE: `${API_BASE_URL}/DeviceInstallationStation/getUnAssignedStationDevice/:InstallationStationID`,
        ASSIGNED_STATION_DEVICE: `${API_BASE_URL}/DeviceInstallationStation/getAssignedStationDevice/:InstallationStationID`,
        SAVE_STATION_DEVICE: `${API_BASE_URL}/DeviceInstallationStation/SaveStationDevice`,

        STATION_CROP_ALL: `${API_BASE_URL}/DeviceInstallationStation/getStationCropAll`,
        ASSIGNED_STATION_CROP: `${API_BASE_URL}/DeviceInstallationStation/getAssignedStationCrop/:InstallationStationID`,
        SAVE_STATION_CROP: `${API_BASE_URL}/DeviceInstallationStation/SaveStationCrop`,
        SAVE_STATION_IMAGES: `${API_BASE_URL}/DeviceInstallationStation/SaveStationImage`,

    },


    COLLATERAL: {
        CROP_CATEGORY: {
            LIST: `${API_BASE_URL}/CropCategory/getCropCategoryAll`,
            // LIST: `${API_BASE_URL}/CropCategory/getCropCategoryAll/:PageIndex/:PageSize`,
            DETAILS: `${API_BASE_URL}/CropCategory/getCropCategory/:CropCategoryID`,
            SAVE: `${API_BASE_URL}/CropCategory/Save`,
            UPDATE: `${API_BASE_URL}/CropCategory/Update`,
            CHANGE_STATUS: `${API_BASE_URL}/CropCategory/StatusUpdate`,
            DELETE: `${API_BASE_URL}/CropCategory/Delete`,
        },

        CROP: {
            LIST: `${API_BASE_URL}/Crop/getCropAll`,
            DETAILS: `${API_BASE_URL}/Crop/getCrop/:CropID`,
            SAVE: `${API_BASE_URL}/Crop/Save`,
            UPDATE: `${API_BASE_URL}/Crop/Update`,
            CHANGE_STATUS: `${API_BASE_URL}/Crop/StatusUpdate`,
            DELETE: `${API_BASE_URL}/Crop/Delete`,
            FILE_UPLOAD: `${API_BASE_URL}/Crop/FileUpload`,

            CULTIVATION_METHODS_VIEW: `${API_BASE_URL}/Crop/getCropCultivationMethod/:CropID`,
            CULTIVATION_METHODS_UPDATE: `${API_BASE_URL}/Crop/SaveCropCultivationMethod`,
            PROTECTION_METHODS_VIEW: `${API_BASE_URL}/Crop/getCropProtectionMethod/:CropID`,
            PROTECTION_METHODS_UPDATE: `${API_BASE_URL}/Crop/SaveCropProtectionMethod`,
        },

        CROP_PROTECTION_METHOD: {
            LIST: `${API_BASE_URL}/CropProtectionMethod/getProtectionMethodAll`,
            DETAILS: `${API_BASE_URL}/CropProtectionMethod/getProtectionMethod/:ProtectionMethodID`,
            SAVE: `${API_BASE_URL}/CropProtectionMethod/Save`,
            UPDATE: `${API_BASE_URL}/CropProtectionMethod/Update`,
            CHANGE_STATUS: `${API_BASE_URL}/CropProtectionMethod/StatusUpdate`,
            DELETE: `${API_BASE_URL}/CropProtectionMethod/Delete`,
        },

    },

    REPORT: {
        FARMER_LIST: `${API_BASE_URL}/Farmer/FarmerListReport`,
        FARMER_CONTACT_LIST: `${API_BASE_URL}/Farmer/FarmerListReport`,
        FARM_LIST: `${API_BASE_URL}/Farm/getFarmListReport`,
        FARMERS_TRAINING_ATTENDANCE_LIST: `${API_BASE_URL}/Event/getEventAll`,
    },

    NOTIFICATION: {
        MANUAL_NOTIFICATION: `${API_BASE_URL}/NotificationBroadcasting/NotificationGet`,
        NOTIFICATION_DETAILS: `${API_BASE_URL}/NotificationBroadcasting/NotificationDetailsGet_ByID`,
        NOTIFICATION_BROADCAST: `${API_BASE_URL}/NotificationBroadcasting/ManualNotificationSave`,
        INVITE_FARMERS: `${API_BASE_URL}/Farmer/FarmerListReport`,
        INVITE_FARMERS_STORE: `${API_BASE_URL}/NotificationBroadcasting/InviteFramerNotification`,

    }
}

export const ACTIONS = {
    RESET_REDUCER: "ACTIONS/RESET_REDUCER",

    LOADER: {
        SET_FP_STATUS: "ACTIONS/LOADER/SET_FP_STATUS",
        SET_SIDEBAR: "ACTIONS/LOADER/SET_SIDEBAR"
    },
    USER: {
        LOGIN: "ACTIONS/USER/LOGIN",
        USER: "ACTIONS/USER/USER",
        LOGOUT: "ACTIONS/USER/LOGOUT",
        FORGET_PASSWORD: "ACTIONS/USER/FORGET_PASSWORD",
        RESET_PASSWORD: "ACTIONS/USER/RESET_PASSWORD",
        PASSWORD_RESET: "ACTIONS/USER/PASSWORD_RESET",
    },
    MASTER: {
        DISTRICTS: "ACTIONS/MASTER/DISTRICTS",
        BLOCKS: "ACTIONS/MASTER/BLOCKS",
        GPUS: "ACTIONS/MASTER/GPUS",
        WARDS: "ACTIONS/MASTER/WARDS",
    }

}

export const SAGA_ACTIONS = {
    USER: {
        LOGIN: "SAGA_ACTIONS/USER/LOGIN",
        LOGOUT: "SAGA_ACTIONS/USER/LOGOUT",
        FORGET_PASSWORD: "SAGA_ACTIONS/USER/FORGET_PASSWORD",
        RESET_PASSWORD: "SAGA_ACTIONS/USER/RESET_PASSWORD",
        PASSWORD_RESET: "SAGA_ACTIONS/USER/PASSWORD_RESET",

    },
    EMPLOYEE: {
        ADD_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/ADD_EMPLOYEE",
        UPDATE_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/UPDATE_EMPLOYEE",
        GET_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/GET_EMPLOYEE",
        GET_ACTIVE_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/GET_ACTIVE_EMPLOYEE",
        GET_EMPLOYEE_DETAILS: "SAGA_ACTIONS/EMPLOYEE/GET_EMPLOYEE_DETAILS",
        UPDATE_EMPLOYEE_STATUS: "SAGA_ACTIONS/EMPLOYEE/UPDATE_EMPLOYEE_STATUS",
        DELETE_EMPLOYEE: "SAGA_ACTIONS/EMPLOYEE/DELETE_EMPLOYEE",


    },

    MASTER: {
        GET_DEPARTMENTS: `SAGA_ACTIONS/MASTER/GET_DEPARTMENTS`,
        GET_DESIGNATIONS: `SAGA_ACTIONS/MASTER/GET_DESIGNATIONS`,
        GET_DISTRICTS: `SAGA_ACTIONS/MASTER/GET_DISTRICTS`,
        GET_GPUS: `SAGA_ACTIONS/MASTER/GET_GPUS`,
        GET_WARDS: `SAGA_ACTIONS/MASTER/GET_WARDS`,
        GET_BLOCKS: `SAGA_ACTIONS/MASTER/GET_BLOCKS`,

        ROLE: {
            LIST: `SAGA_ACTIONS/MASTER/ROLE/LIST`,
            DETAILS: `SAGA_ACTIONS/MASTER/ROLE/DETAILS`,
            SAVE: `SAGA_ACTIONS/MASTER/ROLE/SAVE`,
            UPDATE: `SAGA_ACTIONS/MASTER/ROLE/UPDATE`,
            DELETE: `SAGA_ACTIONS/MASTER/ROLE/DELETE`,

            PERMISSION: `SAGA_ACTIONS/MASTER/ROLE/PERMISSION`,
            PERMISSION_SAVE: `SAGA_ACTIONS/MASTER/ROLE/PERMISSION_SAVE`,
        },
        GET_ROLES: `SAGA_ACTIONS/MASTER/GET_ROLES`,
        SETTINGS: {
            EMAIL_SETTINGS: {
                LIST: `SAGA_ACTIONS/MASTER/SETTINGS/EMAIL_SETTINGS/LIST`,
                DETAILS: `SAGA_ACTIONS/MASTER/SETTINGS/EMAIL_SETTINGS/DETAILS`,
                UPDATE: `SAGA_ACTIONS/MASTER/SETTINGS/EMAIL_SETTINGS/UPDATE`,
            },
        },
    },

    GROUP: {
        GET_GROUPS: `SAGA_ACTIONS/GROUP/GET_GROUPS`,
        GET_GROUP_DETAILS: `SAGA_ACTIONS/GROUP/GET_GROUP_DETAILS`,
        SAVE_GROUP: `SAGA_ACTIONS/GROUP/SAVE_GROUP`,
        UPDATE_GROUP: `SAGA_ACTIONS/GROUP/UPDATE_GROUP`,
        DELETE_GROUP: `SAGA_ACTIONS/GROUP/DELETE_GROUP`,
        CHANGE_STATUS_GROUP: `SAGA_ACTIONS/GROUP/CHANGE_STATUS_GROUP`,
    },

    EVENT: {
        GET_EVENTS: `SAGA_ACTIONS/EVENT/GET_EVENTS`,
        GET_EVENTS_TYPES: `SAGA_ACTIONS/EVENT/GET_EVENTS_TYPES`,
        GET_EVENTS_ORGANISER: `SAGA_ACTIONS/EVENT/GET_EVENTS_ORGANISER`,
        GET_EVENT_DETAILS: `SAGA_ACTIONS/EVENT/GET_EVENT_DETAILS`,
        SAVE_EVENT: `SAGA_ACTIONS/EVENT/SAVE_EVENT`,
        UPDATE_EVENT: `SAGA_ACTIONS/EVENT/UPDATE_EVENT`,
        DELETE_EVENT: `SAGA_ACTIONS/EVENT/DELETE_EVENT`,
        CHANGE_STATUS_EVENT: `SAGA_ACTIONS/EVENT/CHANGE_STATUS_EVENT`,
        UPLOAD_EVENT_ATTENDEE: `SAGA_ACTIONS/EVENT/UPLOAD_EVENT_ATTENDEE`,
        GET_EVENT_FARMER_IMPORT_ALL: `SAGA_ACTIONS/EVENT/GET_EVENT_FARMER_IMPORT_ALL`,
    },

    FARMER: {
        GET_FARMER: `SAGA_ACTIONS/FARMER/GET_FARMER`,
        GET_FARMER_DETAILS: `SAGA_ACTIONS/FARMER/GET_FARMER_DETAILS`,
        CHANGE_STATUS_FARMER: `SAGA_ACTIONS/FARMER/CHANGE_STATUS_FARMER`,
        IMPORT_FARMER: `SAGA_ACTIONS/FARMER/IMPORT_FARMER`,
    },

    FARMS: {
        LIST: `SAGA_ACTIONS/FARMS/LIST`,
        DETAILS: `SAGA_ACTIONS/FARMS/DETAILS`,
        SAVE: `SAGA_ACTIONS/FARMS/SAVE`,
        UPDATE: `SAGA_ACTIONS/FARMS/UPDATE`,
        CHANGE_STATUS: `SAGA_ACTIONS/FARMS/CHANGE_STATUS`,
        DELETE: `SAGA_ACTIONS/FARMS/DELETE`,
        IMPORT_FARMS: `SAGA_ACTIONS/FARMS/IMPORT_FARMS`,

        FARMS_CROP_MAPPING_LIST: `SAGA_ACTIONS/FARMS/FARMS_CROP_MAPPING_LIST`,
        FARMS_CROP_MAPPING_SAVE: `SAGA_ACTIONS/FARMS/FARMS_CROP_MAPPING_SAVE`,

        FIELDS_HISTORY: `SAGA_ACTIONS/FARMS/FIELDS_HISTORY`,

    },
    FORECAST: {
        SENSOR_RANGE_CHECKLIST: `SAGA_ACTIONS/FORECAST/SENSOR_RANGE_CHECKLIST`,
    },

    DASHBOARD: {
        GET_DEVICE_AREAS: `SAGA_ACTIONS/DASHBOARD/GET_DEVICE_AREAS`,
        GET_AREA_PICTURES: `SAGA_ACTIONS/DASHBOARD/GET_AREA_PICTURES`,
        GET_AREA_CHARTS: `SAGA_ACTIONS/DASHBOARD/GET_AREA_CHARTS`,
        GET_AREA_INFORMATIONS: `SAGA_ACTIONS/DASHBOARD/GET_AREA_INFORMATIONS`,

        GET_CURRENT_WEATHER_REPORT: `SAGA_ACTIONS/DASHBOARD/GET_CURRENT_WEATHER_REPORT`,
        GET_WEATHER_HISTORY_REPORT: `SAGA_ACTIONS/DASHBOARD/GET_WEATHER_HISTORY_REPORT`,

    },
    DEVICE_INSTALLATION_STATION: {
        LIST: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/LIST`,
        DETAILS: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/DETAILS`,
        SAVE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/SAVE`,
        UPDATE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/UPDATE`,
        CHANGE_STATUS: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/CHANGE_STATUS`,
        DELETE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/DELETE`,

        UN_ASSIGNED_STATION_DEVICE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/UN_ASSIGNED_STATION_DEVICE`,
        ASSIGNED_STATION_DEVICE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/ASSIGNED_STATION_DEVICE`,
        SAVE_STATION_DEVICE: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/SAVE_STATION_DEVICE`,

        STATION_CROP_ALL: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/STATION_CROP_ALL`,
        ASSIGNED_STATION_CROP: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/ASSIGNED_STATION_CROP`,
        SAVE_STATION_CROP: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/SAVE_STATION_CROP`,
        SAVE_STATION_IMAGES: `SAGA_ACTIONS/DEVICE_INSTALLATION_STATION/SAVE_STATION_IMAGES`,
    },
    DEVICE: {
        GET_DEVICES: `SAGA_ACTIONS/DEVICE/GET_DEVICES`,
        GET_DEVICES_DETAILS: `SAGA_ACTIONS/DEVICE/GET_DEVICES_DETAILS`,
        SAVE_DEVICES: `SAGA_ACTIONS/DEVICE/SAVE_DEVICES`,
        UPDATE_DEVICES: `SAGA_ACTIONS/DEVICE/UPDATE_DEVICES`,
        DELETE_DEVICES: `SAGA_ACTIONS/DEVICE/DELETE_DEVICES`,
        CHANGE_DEVICES_STATUS: `SAGA_ACTIONS/DEVICE/CHANGE_DEVICES_STATUS`,
        DEVICES_WORKING_STATUS: `SAGA_ACTIONS/DEVICE/DEVICES_WORKING_STATUS`,

        GET_DEVICES_TEPLATE: `SAGA_ACTIONS/DEVICE/GET_DEVICES_TEPLATE`,
        DEVICES_TEMPLATE_DETAILS: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_DETAILS`,
        DEVICES_TEMPLATE_SAVE: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_SAVE`,
        DEVICES_TEMPLATE_UPDATE: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_UPDATE`,
        DEVICES_TEMPLATE_CHANGE_STATUS: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_CHANGE_STATUS`,
        DEVICES_TEMPLATE_DELETE: `SAGA_ACTIONS/DEVICE/DEVICES_TEMPLATE_DELETE`,
        DEVICES_PARAM_TYPE_ALL: `SAGA_ACTIONS/DEVICE/DEVICES_PARAM_TYPE_ALL`,
        DEVICES_UNIT_MASTER_ALL: `SAGA_ACTIONS/DEVICE/DEVICES_UNIT_MASTER_ALL`,
        DEVICES_PARAM_MASTER_ALL: `SAGA_ACTIONS/DEVICE/DEVICES_PARAM_MASTER_ALL`,


        CATEGORY_UNIT: {
            LIST: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/LIST`,
            DETAILS: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/DETAILS`,
            SAVE: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/SAVE`,
            UPDATE: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/UPDATE`,
            CHANGE_STATUS: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/CHANGE_STATUS`,
            DELETE: `SAGA_ACTIONS/DEVICE/CATEGORY_UNIT/DELETE`,
        }
    },


    COLLATERAL: {
        CROP_CATEGORY: {
            LIST: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/LIST`,
            DETAILS: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/DETAILS`,
            SAVE: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/SAVE`,
            UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/UPDATE`,
            CHANGE_STATUS: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/CHANGE_STATUS`,
            DELETE: `SAGA_ACTIONS/COLLATERAL/CROP_CATEGORY/DELETE`,
        },

        CROP: {
            LIST: `SAGA_ACTIONS/COLLATERAL/CROP/LIST`,
            DETAILS: `SAGA_ACTIONS/COLLATERAL/CROP/DETAILS`,
            SAVE: `SAGA_ACTIONS/COLLATERAL/CROP/SAVE`,
            UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP/UPDATE`,
            CHANGE_STATUS: `SAGA_ACTIONS/COLLATERAL/CROP/CHANGE_STATUS`,
            DELETE: `SAGA_ACTIONS/COLLATERAL/CROP/DELETE`,
            FILE_UPLOAD: `SAGA_ACTIONS/COLLATERAL/CROP/FILE_UPLOAD`,

            CULTIVATION_METHODS_VIEW: `SAGA_ACTIONS/COLLATERAL/CROP/CULTIVATION_METHODS_VIEW`,
            CULTIVATION_METHODS_UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP/CULTIVATION_METHODS_UPDATE`,
            PROTECTION_METHODS_VIEW: `SAGA_ACTIONS/COLLATERAL/CROP/PROTECTION_METHODS_VIEW`,
            PROTECTION_METHODS_UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP/PROTECTION_METHODS_UPDATE`,

        },

        CROP_PROTECTION_METHOD: {
            LIST: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/LIST`,
            DETAILS: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/DETAILS`,
            SAVE: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/SAVE`,
            UPDATE: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/UPDATE`,
            CHANGE_STATUS: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/CHANGE_STATUS`,
            DELETE: `SAGA_ACTIONS/COLLATERAL/CROP_PROTECTION_METHOD/DELETE`,
        },

    },


    REPORT: {
        FARMER_LIST: `SAGA_ACTIONS/REPORT/FARMER_LIST`,
        FARMER_CONTACT_LIST: `SAGA_ACTIONS/REPORT/FARMER_CONTACT_LIST`,
        FARM_LIST: `SAGA_ACTIONS/REPORT/FARM_LIST`,
        FARMERS_TRAINING_ATTENDANCE_LIST: `SAGA_ACTIONS/REPORT/FARMERS_TRAINING_ATTENDANCE_LIST`,
    },

    NOTIFICATION: {
        MANUAL_NOTIFICATION: `SAGA_ACTIONS/NOTIFICATION/MANUAL_NOTIFICATION`,
        NOTIFICATION_DETAILS: `SAGA_ACTIONS/NOTIFICATION/NOTIFICATION_DETAILS`,
        NOTIFICATION_BROADCAST: `SAGA_ACTIONS/NOTIFICATION/NOTIFICATION_BROADCAST`,

        INVITE_FARMERS: `SAGA_ACTIONS/NOTIFICATION/INVITE_FARMERS`,
        INVITE_FARMERS_STORE: `SAGA_ACTIONS/NOTIFICATION/INVITE_FARMERS_STORE`,
    }




}