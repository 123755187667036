import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import Pagination from "../../../components/pagination/Pagination";
import Footer from "../../../components/footer/Footer";
import Search from "../../../components/search/Search";
import { Link } from "react-router-dom";
import Icon from "../../../components/icon";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import { useUser, useUserPermissions } from "../../../_common/hooks/authHook";
import { replaceUrlParams } from "../../../_common/functions";
import { URLS } from "../../../_config";
import { ConfirmAlert } from "../../../_common/confirmAlert";
import TableLoader from "../../../components/tableLoader";
import Status from "../../../components/status";
import { Form } from "react-bootstrap";
import parse from 'html-react-parser';

const CropProtectionMethods = () => {
    const collateralApi = useCollatralApi()
    const [dataList, setDataList] = useState<any>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 20;
    const user: any = useUser()
    const permissions: any = useUserPermissions();

    const getCropProtectionMethodList = () => {
        collateralApi.cropProtectionMethodList({
            PageIndex: page,
            PageSize: limit,
            query: search

        }, (message: any, resp: any) => {
            setDataList(resp.data.protectionMethod)
            setTotal(resp.totalRecords)
        }, (message: any) => {
            setDataList([])
            setTotal(0)
        })
    }


    const dataItemChangeStatus = (params: any) => {
        return new Promise((resolve, reject) => {
            collateralApi.cropProtectionMethodChangeStatus(params, resolve, reject)
        })
    }

    const dataItemChangeStatusHandler = (el: any, dataItem: any) => {
        ConfirmAlert.changeStatus('Crop Protection Method', dataItemChangeStatus, {
            "protectionMethodID": dataItem.protectionMethodID,
            "updatedBy": user?.userID,
            "isActive": el.checked,
            "isDeleted": false,
        }).then(() => {
            getCropProtectionMethodList();
        }).catch(() => {
            el.checked = !el.checked
        })

    }

    const deleteDataItem = (dataItem: any) => {
        return new Promise((resolve, reject) => {
            collateralApi.cropProtectionMethodDelete({
                "protectionMethodID": dataItem.protectionMethodID,
                "updatedBy": user?.userID,
                "isDeleted": true,
            }, resolve, reject)
        })
    }

    const deleteDataItemhandler = (dataItem: any) => {
        ConfirmAlert.delete('Crop Protection Method', deleteDataItem, dataItem).then(() => {
            getCropProtectionMethodList();
        })
    }

    useEffect(() => {
        if(dataList != null){
            setDataList(null)
            getCropProtectionMethodList();
        }
        
    }, [page, search])
    useEffect(() => {
        getCropProtectionMethodList();
    }, [])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Crop Protection Methods`}
                            breadcrumbs={{
                                'Collateral': '#',
                                'Crop Protection Methods': '#',
                            }} />


                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " data-aos="fade-up">
                                    <div className="d-flex justify-content-between align-items-center  mb-4 ">
                                        <h2 className="card-heading mb-4">Crop Protection List</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            <Search setSearch={(str: string) => {
                                                setSearch(str)
                                                
                                                setPage(1)
                                            }}></Search>
                                            {
                                                permissions.menus['Collateral'].write &&
                                                <Link to={URLS.COLLATERAL.CROP_PROTECTION_METHOD.ADD} className="btn btn-primary d-flex align-items-center gap-2 "><Icon name="plus" size={16} /> Add New</Link>
                                            }
                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: 180 }}>Crop Disease Image</th>
                                                    <th scope="col">Crop Disease or Pest Name</th>
                                                    <th scope="col">Partial Information Of Symptoms</th>
                                                    {/* <th scope="col">Partial Information On Protection Methods</th> */}
                                                    <th scope="col" >Status</th>
                                                    <th scope="col" style={{ width: 80 }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataList && dataList.length > 0 &&
                                                    dataList.map((item: any) => (
                                                        <tr key={`method${item.protectionMethodID}`}>
                                                            <td><div className="table-pic"><img src={item?.image?item?.image:'/images/no_image.jpg'} alt="#" className="category-img" /></div></td>
                                                            <td>{item?.pestName}</td>
                                                            <td>{parse(item?.symptoms)}</td>
                                                            {/* <td>{parse(item?.preventiveMeasures)}</td> */}
                                                            <td>
                                                                <Status status={item.isActive} />
                                                            </td>
                                                            <td>
                                                                <div className="action-btns">

                                                                    {
                                                                        permissions.menus['Collateral'].write &&
                                                                        <>
                                                                            <Form.Check
                                                                                type="switch"
                                                                                id={`status-switch-${item.cropCategoryID}`}
                                                                                label=""
                                                                                onChange={(e) => { dataItemChangeStatusHandler(e.target, item) }}
                                                                                defaultChecked={item.isActive}
                                                                            />
                                                                            <Link to={replaceUrlParams(URLS.COLLATERAL.CROP_PROTECTION_METHOD.EDIT, { id: item.protectionMethodID })}><Icon name="edit" size={18}/></Link>

                                                                        </>
                                                                    }

                                                                    <Link to={replaceUrlParams(URLS.COLLATERAL.CROP_PROTECTION_METHOD.DETAILS, { id: item.protectionMethodID })}>
                                                                        <Icon name="view" size={18} />
                                                                    </Link>

                                                                    {
                                                                        permissions.menus['Collateral'].delete &&
                                                                        <Icon name="delete" size={18}
                                                                            onClick={() => deleteDataItemhandler(item)} />
                                                                    }
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))
                                                }


                                            </tbody>
                                        </table>
                                        <TableLoader data={dataList} />

                                        {
                                            dataList && <Pagination total={total} page={page} setPage={(n: number) => {
                                                setPage(n)
                                                //setDataList(null)
                                            }} pageLimit={limit} />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>


                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default CropProtectionMethods;