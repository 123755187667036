import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftPanel from "../../../components/leftPanel/LeftPanel";
import TitleBar from "../../../components/TopBar/TitleBar";
import Pagination from "../../../components/pagination/Pagination";
import Footer from "../../../components/footer/Footer";
import Search from "../../../components/search/Search";
import { Link } from "react-router-dom";
import Icon from "../../../components/icon";
import { useCollatralApi } from "../../../_common/hooks/api/collateralApiHook";
import { useUser, useUserPermissions } from "../../../_common/hooks/authHook";
import { replaceUrlParams } from "../../../_common/functions";
import { URLS } from "../../../_config";
import { ConfirmAlert } from "../../../_common/confirmAlert";
import TableLoader from "../../../components/tableLoader";
import Status from "../../../components/status";
import { Form } from "react-bootstrap";
import parse from 'html-react-parser';
import { MAPAPI_BASE_URL } from "../../../_config/site_urls";
import EditModelParamsModal from "../../map/modals/editModelParams.modal";
import { set } from "lodash";

const ModelParams = () => {
    const collateralApi = useCollatralApi()
    const [dataList, setDataList] = useState<any>(null);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const limit = 10;
    const user: any = useUser()
    const permissions: any = useUserPermissions();

    const [editModel, setEditModel] = useState<any>(null);
    const [openEditModel, setOpenEditModel] = useState<boolean>(false);

    const getModelParams = async () => {
        
        const response = await fetch(MAPAPI_BASE_URL + `/predictions/models?${new URLSearchParams({
            page: page.toString(),
            per_page: limit.toString(),
        })}`);

        const data = await response.json();
        setDataList(data.data.prediction_models);
        setTotal(data.data.total_count);
        
    }


    const dataItemChangeStatus = (params: any) => {
        return new Promise((resolve, reject) => {
            collateralApi.cropProtectionMethodChangeStatus(params, resolve, reject)
        })
    }

    const dataItemChangeStatusHandler = (el: any, dataItem: any) => {
        ConfirmAlert.changeStatus('Crop Protection Method', dataItemChangeStatus, {
            "protectionMethodID": dataItem.protectionMethodID,
            "updatedBy": user?.userID,
            "isActive": el.checked,
            "isDeleted": false,
        }).then(() => {
            getModelParams();
        }).catch(() => {
            el.checked = !el.checked
        })

    }

    const deleteDataItem = async (dataItem: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(MAPAPI_BASE_URL + `/predictions/models/${dataItem._id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const result = await response.json();
                if (response.ok) {
                    resolve(result);
                } else {
                    reject(result);
                }
            } catch (error) {
                reject(error);
            }
        })
    }

    const deleteDataItemhandler = (dataItem: any) => {
        ConfirmAlert.delete('Model Param', deleteDataItem, dataItem).then(() => {
            getModelParams();
        })
    }

    useEffect(() => {
        if (dataList != null) {
            setDataList(null)
            getModelParams();
        }

    }, [page, search])
    useEffect(() => {
        getModelParams();
    }, [])

    useEffect(() => {
        setOpenEditModel(editModel != null)
    }, [editModel])


    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Model Params`}
                            breadcrumbs={{
                                'Settings': '#',
                                'Model Params': '#',
                            }} />


                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " data-aos="fade-up">
                                    <div className="flex justify-between items-center  mb-4 ">
                                        <h2 className="card-heading">Model Params</h2>
                                        <div className="d-flex align-items-center gap-2 ">
                                            {/* <Search setSearch={(str: string) => {
                                                setSearch(str)

                                                setPage(1)
                                            }}></Search> */}
                                            {/* {
                                                permissions.menus['Collateral'].write &&
                                                <Link to={URLS.COLLATERAL.CROP_PROTECTION_METHOD.ADD} className="btn btn-primary d-flex align-items-center gap-2 "><Icon name="plus" size={16} /> Add New</Link>
                                            } */}
                                            <button className="btn btn-primary d-flex align-items-center gap-2" onClick={() => {setOpenEditModel(true)} }>Add New Model Params</button>
                                        </div>
                                    </div>
                                    <div className="table-wrapper">
                                        <table className="table gen-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ width: 180 }}>Sensor Name</th>
                                                    <th scope="col">Model Name</th>
                                                    <th scope="col">Algorithm</th>
                                                    <th scope="col" >RMSE</th>
                                                    <th scope="col" >Accuracy</th>
                                                    <th scope="col" >Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataList && dataList.length > 0 &&
                                                    dataList.map((item: any) => (
                                                        <tr key={`method${item.protectionMethodID}`}>
                                                            
                                                            <td>{item.sensor_name}</td>
                                                            <td>{item.model_name}</td>
                                                            <td>{item.model_algorithm}</td>
                                                            <td>{item.rsme}</td>
                                                            <td>{item.accuracy_score}</td>
                                                            <td>
                                                               <div className="flex gap-2">
                                                               <Icon name="edit" size={16} className="me-2" onClick={()=>{
                                                                    setEditModel(item)
                                                                }} />

                                                                <Icon name="delete" size={16} onClick={() => {
                                                                    deleteDataItemhandler(item)
                                                                }} />
                                                               </div>
                                                            </td>
                                                        

                                                        </tr>
                                                    ))
                                                }


                                            </tbody>
                                        </table>
                                        <TableLoader data={dataList} />

                                        {
                                            dataList && <Pagination total={total} page={page} setPage={(n: number) => {
                                                setPage(n)
                                                //setDataList(null)
                                            }} pageLimit={limit} />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <EditModelParamsModal open={openEditModel} close={()=>{setOpenEditModel(false); setEditModel(null)}} update={getModelParams} data={editModel} />
                <Footer></Footer>
            </div>
        </React.Fragment>
    )
}

export default ModelParams;