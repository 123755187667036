import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import LeftPanel from '../../components/leftPanel/LeftPanel';
import TitleBar from '../../components/TopBar/TitleBar';
import { useFarmsApi } from '../../_common/hooks/api/farmApiHook';
import { MAPAPI_BASE_URL } from '../../_config/site_urls';
import Select from 'react-select';
import Loader from '../../components/loader';
import { useForm } from 'react-hook-form';
import { set } from 'lodash';
import toast from 'react-hot-toast';
import moment from 'moment';
import Icon from '../map/components/icon';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';


const DroneImageUpload: React.FC = () => {

    const [selectedField, setSelectedField] = useState<any>(null);

    const [selectedDroneImage, setSelectedDroneImage] = useState<File | null>(null);
    const [droneImagePreview, setDroneImagePreview] = useState<string | null>(null);

    const [selectedDroneFile, setSelectedDroneFile] = useState<File | null>(null);

    const [selectedAssets, setSelectedAssets] = useState<File[] | null>(null);
    const [assetsPreview, setAssetsPreview] = useState<string[] | null>(null);

    const [selectedReports, setSelectedReports] = useState<File[] | null>(null);

    const {showLoader, hideLoader} = useAppLoader();
    
    const [preview, setPreview] = useState<string | null>(null);
    const farmsApi = useFarmsApi();
    const [fields, setFields] = useState<any[] | null>(null);
    const [date, setDate] = useState<any>(null);

    const [tab, setTab] = useState<string>('droneImage');

    const { control, reset, formState: { errors }, handleSubmit, watch } = useForm();

    const stages: any[] = [
        { value: 'Pre-Sowing Stage', label: 'Pre-Sowing Stage' },
        { value: 'Sowing and Germination Stage', label: 'Sowing and Germination Stage' },
        { value: 'Early Growth/Vegetative Stage', label: 'Early Growth/Vegetative Stage' },
        { value: 'Peak Vegetative Stage', label: 'Peak Vegetative Stage' },
        { value: 'Flowering and Reproductive Stage', label: 'Flowering and Reproductive Stage' },
        { value: 'Ripening/Maturity Stage', label: 'Ripening/Maturity Stage' },
        { value: 'Post-Harvest Stage', label: 'Post-Harvest Stage' }
    ];

    const [selectedStage, setSelectedStage] = useState<any>(null);

    const getFarmList = () => {
        farmsApi.farmList({
            PageIndex: 1,
            PageSize: 100
        }, (message: any, resp: any) => {
            getFields().then((fields: any) => {
                resp.data.farm.map((farm: any) => {
                    fields.map((field: any) => {
                        if(field.farm_id == farm.farmID){
                            farm.map = field;
                            farm.value = field._id;
                            farm.label = farm.khasraNumber;
                        }
                    })
                    return farm;
                });
                console.log(resp.data.farm.filter((farm: any) => farm.map));
                setFields(resp.data.farm.filter((farm: any) => farm.map))
            });
            
        }, (message: any) => {
            setFields([])
        })
    }

    const getFields = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await fetch(MAPAPI_BASE_URL + "/fields");
                const data = await response.json();
                setFields(data);
                resolve(data);
                //hideLoader();
            } catch (error) {
                //hideLoader();
                console.error("Error fetching data:", error);
                reject(error);
            }
        });

    };

    const handleDroneImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedDroneImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setDroneImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedDroneImage(null);
        }
    };
    const handleDroneFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedDroneFile(file);
        } else {
            setSelectedDroneFile(null);
        }
    };
    

    const handleAssestsFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

        let alreadyHave = selectedReports?.length || 0;
        let files = event.currentTarget.files; // puts all files into an array
        let _files = [];
        let overSizedFiles = [];
        let afterFileLimit = [];
        if (files) {
            for (var i in files) {
                if (typeof files[i] == 'object') {
                    if ((_files.length + alreadyHave) < 6) {
                        let size: number = parseFloat(((files[i].size / 1024) / 1024).toFixed(4));
                        if (size <= 3) {
                            _files.push(files[i]);
                        } else {
                            overSizedFiles.push(files[i]);
                        }
                    } else {
                        afterFileLimit.push(files[i]);
                    }
                } else {
                    console.log('Not a file');
                }
            }
        }

        if(selectedAssets == null || selectedAssets?.length == 0){
            setSelectedAssets(_files.length > 0 ? _files : null);
        } else {
            setSelectedAssets([...selectedAssets, ..._files]);
        }

        let msg = [];
        if (overSizedFiles.length > 0) {
            msg.push(`Following files are over sized:\n ${overSizedFiles.map((file: any) => file.name).join(',\n')}`);
        }

        if (afterFileLimit.length > 0) {
            msg.push(`following files are not uploaded:\n ${afterFileLimit.map((file: any) => file.name).join(',\n')}. You can upload maximum 6 files.`);
        }

        if (msg.length > 0) {
            toast.error(msg.join('\n\n'));
        }

        event.currentTarget.value = '';

        /* for (let i = 0; i < (event.target.files?.length || 0); i++) {
            files.push(event.target.files?.[i]);
            let reader = new FileReader();
            reader.onloadend = () => {
                prev.push(reader.result as string);
                setAssetsPreview(prev);
            };
            reader.readAsDataURL(event.target.files?.[i] as any);
        } */

    };

    const getImagePreview = (file: any) => {
        return <img src={URL.createObjectURL(file)} alt="Preview" className='overflow-hidden rounded-sm' style={{ width: 'auto', height: '100px' }} />
    }



    const handleReportsFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let alreadyHave = selectedReports?.length || 0;
        let files = event.currentTarget.files; // puts all files into an array
        let _files = [];
        let overSizedFiles = [];
        let afterFileLimit = [];
        if (files) {
            for (var i in files) {
                if (typeof files[i] == 'object') {
                    if ((_files.length + alreadyHave) < 5) {
                        let size: number = parseFloat(((files[i].size / 1024) / 1024).toFixed(4));
                        if (size <= 2) {
                            _files.push(files[i]);
                        } else {
                            overSizedFiles.push(files[i]);
                        }
                    } else {
                        afterFileLimit.push(files[i]);
                    }
                } else {
                    console.log('Not a file');
                }
            }
        }

        if(selectedReports == null || selectedReports?.length == 0){
            setSelectedReports(_files.length > 0 ? _files : null);
        } else {
            setSelectedReports([...selectedReports, ..._files]);
        }

        let msg = [];
        if (overSizedFiles.length > 0) {
            msg.push(`Following files are over sized:\n ${overSizedFiles.map((file: any) => file.name).join(',\n')}`);
        }

        if (afterFileLimit.length > 0) {
            msg.push(`following files are not uploaded:\n ${afterFileLimit.map((file: any) => file.name).join(',\n')}. You can upload maximum 5 files.`);
        }

        if (msg.length > 0) {
            toast.error(msg.join('\n\n'));
        }

        console.log('Message:', overSizedFiles, afterFileLimit);

        console.log('Message:', msg);

        event.currentTarget.value = '';
       
    };

    const uploadDroneImage = async () => {
        let formData = new FormData();
        if (selectedDroneImage != null && selectedDroneFile != null) {
            formData.append('files', selectedDroneImage);
            formData.append('files', selectedDroneFile);
        }
        showLoader();
        const response = await fetch(MAPAPI_BASE_URL + `/sensors/${selectedField.map._id}/upload/files?${new URLSearchParams({
            upload_date: moment(date).format('YYYY-MM-DD'),
            is_assets: 'false',
            field_id: selectedField.map._id
        })}`,{
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            }
        });
        const data = await response.json();
        hideLoader();
        uploadAssets();
    };

    const finishUpload = async () => {
        setSelectedAssets(null);
        setSelectedReports(null);
        setSelectedDroneFile(null);
        setSelectedDroneImage(null);
        setDroneImagePreview(null);
        setPreview(null);
        setDate(null);
        
        const response = await fetch(MAPAPI_BASE_URL + `/data-processing/${selectedField.map._id}?${new URLSearchParams({
            from_date: moment(date).format('YYYY-MM-DD'),
            to_date:  moment(date).format('YYYY-MM-DD'),
            field_or_farm_id: selectedField.map._id
        })}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            }
        });
        const data = await response.json();
    };

    const uploadAssets = async () => {
        let formData = new FormData();
        let files:any = [];
        if(selectedAssets != null){
            for (let i = 0; i < (selectedAssets?.length || 0); i++) {
                files.push(selectedAssets?.[i]);
            }
        }
        if(selectedReports != null){
            for (let i = 0; i < (selectedReports?.length || 0); i++) {
                files.push(selectedReports?.[i]);
            }
        }
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append(`files`, files?.[i] );
            }
            showLoader();
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/${selectedField.map._id}/upload/files?${new URLSearchParams({
                upload_date: moment(date).format('YYYY-MM-DD'),
                is_assets: 'true',
                field_id: selectedField.map._id
            })}`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                }
            });
            const data = await response.json();
            console.log('result', data);
            toast.success('File uploaded successfully');
            finishUpload();
            hideLoader();
        } else {
            toast.success('File uploaded successfully');
            finishUpload();
        }
    };

    const handleUpload = () => {
        console.log(selectedDroneImage, selectedDroneFile, selectedAssets, selectedReports);
        if((selectedDroneImage  == null || selectedDroneFile == null) && selectedAssets == null && selectedReports == null){
            toast.error('Please select drone image and file or assets or reports');
            return;
        } else {
            if(selectedDroneImage  == null || selectedDroneFile == null){

                let conf = (window as any).confirm('Are you sure you want to upload assets or reports only?');
                if(conf){
                    uploadAssets();
                    return;
                } 
            } else {
                uploadDroneImage();
            }
        }
    };

    const createOptionLabel = (option: any) => {

        let canvasRef = React.createRef<HTMLCanvasElement>();

        const canvas = canvasRef.current;
        if(canvas){
            const ctx = canvas.getContext('2d');
            if (ctx) {
                const width = canvas.width = 60;
                const height = canvas.height = 60;
                ctx.fillStyle = '#94a3b8';
                ctx.strokeStyle = '#334155';
                ctx.lineWidth = 1;
    
                if (option.map.boundary && option.map.boundary.length > 2) {
                    let points = option.map.boundary.map((point: any) => {
                        return [point.longitude, point.latitude];
                    });
    
                    let minX: any, minY: any, maxX: any, maxY: any;
    
                    points.forEach((p: any, i: any) => {
                        if (i === 0) { // if first point 
                            minX = maxX = p[0];
                            minY = maxY = p[1];
                        } else {
                            minX = Math.min(p[0], minX);
                            minY = Math.min(p[1], minY);
                            maxX = Math.max(p[0], maxX);
                            maxY = Math.max(p[1], maxY);
                        }
                    });
                    // now get the map width and heigth in its local coords
                    const mapWidth = maxX - minX;
                    const mapHeight = maxY - minY;
                    const mapCenterX = (maxX + minX) / 2;
                    const mapCenterY = (maxY + minY) / 2;
    
                    // to find the scale that will fit the canvas get the min scale to fit height or width
                    const scale = Math.min(canvas.width / mapWidth, canvas.height / mapHeight);
    
    
                    ctx.beginPath();
                    points.forEach((p: any) => {
                        ctx.lineTo(
                            (p[0] - mapCenterX) * scale + canvas.width / 2,
                            (p[1] - mapCenterY) * scale + canvas.height / 2
                        );
                    });
    
    
                } else {
    
                    ctx.beginPath();
                    ctx.moveTo(0, 0);
                    ctx.lineTo(100, 50);
                    ctx.lineTo(50, 100);
                    ctx.lineTo(0, 90);
                    ctx.lineTo(0, 0);
                }
                ctx.stroke();
                ctx.closePath();
                ctx.fill();
    
    
    
    
    
    
    
    
            }
        }

        return (
            <div className='h-[60px]'>
               {/*  <div>
                    <canvas ref={canvasRef} className='' style={{transform: 'scaleY(-1)'}}></canvas>
                </div> */}
                <div>
                    <div className='font-semibold'>{option.map.name}</div>
                    <div className='font-size-12'>{option.ownerName}</div>
                    <div className='font-size-12'>{option.khasraNumber}</div>
                </div>

            </div>
        )
    };

    useEffect(() => {
       getFarmList();
    }, []);

    const removeAssets = (event:any, index: number) => {
        event.preventDefault();
        let files = selectedAssets?[...selectedAssets]:[];
        files?.splice(index, 1);
        setSelectedAssets(files);
    }
    const removeReprots = (event:any, index: number) => {
        event.preventDefault();
        let files = selectedReports?[...selectedReports]:[];
        files?.splice(index, 1);
        setSelectedReports(files);
    }

    return (
        <React.Fragment>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">
                        <TitleBar title={`Drone Image and Assets Upload`}
                            breadcrumbs={{
                                'Settings': '#',
                                'Drone Image and Assets Upload': '#',
                            }} />
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="white-box-card mb-4 h-100 " data-aos="fade-up">
                                    {fields ?
                                        <div>

                                            <div className='flex gap-2'>
                                                <div className="form-group float-label-wrap max-w-[420px]" style={{ flexGrow: 1 }}>
                                                    <label htmlFor="" className="float-label">Location</label>
                                                    <Select
                                                        formatOptionLabel={createOptionLabel}
                                                        options={fields}
                                                        onChange={setSelectedField}
                                                    />
                                                </div>

                                                <div className="form-group float-label-wrap max-w-[300px]" style={{ flexGrow: 1 }}>
                                                    <label htmlFor="" className="float-label">Date</label>
                                                    <input type="date" value={date} className="form-control" onChange={(e: any) => {
                                                        setDate(e.target.value)
                                                    }} />
                                                </div>

                                                {/* <div className="form-group float-label-wrap max-w-[420px]" style={{ flexGrow: 1 }}>
                                                    <label htmlFor="" className="float-label">Growth Stage</label>
                                                    <Select
                                                        options={stages}
                                                        onChange={setSelectedStage}
                                                    />
                                                </div> */}
                                            </div>

                                            <div>
                                                <div className='flex gap-1 border-b border-cyan-700'>
                                                    <button className={`rounded-lg rounded-b-none px-3 py-1 ${tab == 'droneImage'?'bg-cyan-700 text-white':'bg-slate-50 '}`} onClick={()=>{setTab('droneImage')}}>Drone Image</button>
                                                    <button className={`rounded-lg rounded-b-none px-3 py-1 ${tab == 'assets'?'bg-cyan-700 text-white':'bg-slate-50 '}`}  onClick={()=>{setTab('assets')}}>Assets</button>
                                                    <button className={`rounded-lg rounded-b-none px-3 py-1 ${tab == 'reports'?'bg-cyan-700 text-white':'bg-slate-50 '}`}  onClick={()=>{setTab('reports')}}>Reports</button>
                                                </div>
                                                <div className='p-2'>
                                                    <div className={`${tab != 'droneImage'?'hidden':''}`}>
                                                        <div className='flex gap-2'>
                                                            
                                                            <div className="form-group float-label-wrap max-w-[340px]" style={{ flexGrow: 1 }}>
                                                                <label htmlFor="">Drone Image</label>
                                                                <input type="file" accept=".png" className='form-control' onChange={handleDroneImageChange} />
                                                                {droneImagePreview && <img src={droneImagePreview} alt="Preview" className='mt-2 overflow-hidden rounded-sm' style={{ width: 'auto', height: '100px' }} />}
                                                            </div>
                                                            <div className="form-group float-label-wrap max-w-[340px]" style={{ flexGrow: 1 }}>
                                                                <label htmlFor="">Drone File</label>
                                                                <input type="file" accept=".csv" className='form-control' onChange={handleDroneFileChange} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className={`${tab != 'assets' ? 'hidden' : ''}`}>
                                                        <div className="form-group float-label-wrap max-w-[340px]" style={{ flexGrow: 1 }}>
                                                            <label htmlFor="">Assets (maximum 6 image, max size 3MB)</label>
                                                            <input type="file" multiple={true} accept=".png,.jpg" className='form-control' onChange={handleAssestsFileChange} />
                                                        </div>
                                                        {selectedAssets &&
                                                            <div className='flex flex-wrap gap-2'>
                                                                {selectedAssets.map((file: any, index: number) => (
                                                                    <div key={`report-pdf-${file.name}`} className='relative  mb-2'>
                                                                        <div className='absolute right-1 top-1 bg-gray-200 rounded-full p-1'>
                                                                            <Icon name='times' className='text-gray-500 hover:text-red-700' size={16} onClick={(e:any) => {
                                                                                removeAssets(e, index);
                                                                            }} />
                                                                        </div>
                                                                        {getImagePreview(file)}
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        }

                                                    </div>
                                                    <div className={`${tab != 'reports' ? 'hidden' : ''}`}>
                                                        <div className="form-group float-label-wrap max-w-[340px]" style={{ flexGrow: 1 }}>
                                                            <label htmlFor="">Reports (maximum 5 pdf file, max size 2MB)</label>
                                                            <input type="file" multiple={true} accept=".pdf" className='form-control' onChange={handleReportsFileChange} />
                                                        </div>
                                                        {selectedReports &&
                                                            <div className=''>
                                                                {selectedReports.map((file: any, index: number) => (
                                                                    <div key={`report-pdf-${file.name}`} className='relative flex gap-2 items-center p-2 rounded mb-2 bg-slate-200'>
                                                                        <div className='absolute right-1 top-1'>
                                                                            <Icon name='times' className='text-gray-500 hover:text-red-700' size={20} onClick={(e:any) => {
                                                                                removeReprots(e, index);
                                                                                
                                                                            }} />
                                                                        </div>
                                                                        <Icon name='pdf' className='text-red-500' size={32} />
                                                                        <div>
                                                                            <div className='font-semibold'>{file.name}</div>
                                                                            <div className='font-size-12'>{(file.size / 1024 / 1024).toFixed(2)} MB</div>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <button className={`btn-primary rounded-sm font-semibold text-white px-3 py-1 shadow-md font-size-16 ${(selectedField && date)?'':'opacity-50'}`} disabled={!(selectedField && date)} onClick={handleUpload}>Upload</button>
                                        </div>
                                    : <Loader />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DroneImageUpload;