import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Transition } from "@headlessui/react";
import { API_BASE_URL } from "../_config";
import Select from 'react-select';
import moment from "moment";
import toast from "react-hot-toast";
import { GoogleMap, Marker } from "@react-google-maps/api";
//import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';
import { useAppLoader } from "../../../_common/hooks/common/appLoaderHook";
import { Button, Modal } from 'react-bootstrap'
import { CiCirclePlus } from "react-icons/ci";
import { MAPAPI_BASE_URL } from "../../../_config/site_urls";
import { fr } from "date-fns/locale";
import { set } from "lodash";
interface EditModelParamsModalProps {
    open: boolean;
    close: () => void;
    update: () => void;
    data: any;
}

interface FormData {
    modelName: any;
    modelType: any;
    sensor: any;
    field: any;
    algorithm: any;
    accuracy: any;
    rsme: any;
    file:any;
    
}

const EditModelParamsModal = ({ open, close, data, update }: EditModelParamsModalProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { showLoader, hideLoader } = useAppLoader();
    const { control, register, handleSubmit, setValue, reset, watch, formState: { errors, isValid } } = useForm<FormData>({ mode: 'onBlur' });
    const [devices, setDevices] = useState<any[] | null>(null);
    const [crops, setCrops] = useState<any[] | null>(null);

    const [map, setMap] = useState<any>(null);
    const [isloaded, setIsloaded] = useState<boolean>(false);
    const [latLng, setLatLng] = useState({ lat: 27.3516407, lng: 88.6065026 });
    const [placeService, setPlaceService] = useState<any>(null);

    const [sensors, setSensors] = useState<any[] | null>(null);
    const [fields, setFields] = useState<any[] | null>(null);


    const [fieldLatLng, setFieldLatLng] = useState<any | null>(null);

    const mapRef: any = useRef(null);
    const mapboxRef: any = useRef();
    const [roundedArea, setRoundedArea] = useState<any>('');
    const [coordinates, setCoordinates] = useState<any>(null);









    const getSensors = async () => {
        showLoader();
        try {
            const response = await fetch(MAPAPI_BASE_URL + "/sensors");
            const data = await response.json();
            setSensors(data.map((sensor: any) => ({ ...sensor, value: sensor._id, label: sensor.name })));
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    const getFields = async () => {
        showLoader();
        try {
            const response = await fetch(MAPAPI_BASE_URL + "/fields");
            const data = await response.json();
            setFields(data.map((field: any) => ({ ...field, value: field._id, label: field.name })));
            hideLoader();
        } catch (error) {
            console.error("Error fetching data:", error);
            hideLoader();
        }
    };

    const onSubmit = async (fromData: FormData) => {



        showLoader();
        try {

            const form = new FormData();
            
            form.append('model_name', fromData.modelName);
            form.append('sensor_id', fromData.sensor._id);
            form.append('field_id', fromData.field._id);
            form.append('model_algorithm', fromData.algorithm);
            form.append('accuracy_score', fromData.accuracy);
            form.append('rsme', fromData.rsme);
            if (fromData.file && fromData.file[0]) {
                form.append('file', fromData.file[0]);
            }
            if (data && data._id) {
                const response = await fetch(MAPAPI_BASE_URL + `/predictions/${data._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: form
                });

                const result = await response.json();
                hideLoader();
                if (response.ok) {
                    toast.success("Model updated successfully");
                    update();
                    close();
                } else {
                    toast.error(result.message);
                }
            } else {
                const response = await fetch(MAPAPI_BASE_URL + `/predictions/models`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: form
                });

                const result = await response.json();
                hideLoader();
                if (response.ok) {
                    toast.success("Model Added successfully");
                    update();
                    close();
                } else {
                    toast.error(result.message);
                }
            }
        } catch (error) {
            console.error("Error updating model:", error);
            hideLoader();
        }
    };



    useEffect(() => {
        getSensors();
        getFields();
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if(open  && sensors && fields){
            if(data){
                setValue('modelName', data.model_name);
                setValue('modelType', data.model_type);
                setValue('sensor', sensors.find((sensor:any)=>sensor._id == data.sensor_id));
                setValue('field', fields.find((field:any)=>field._id == data.field_id));
                setValue('algorithm', data.model_algorithm);
                setValue('accuracy', data.accuracy_score);
                setValue('rsme', data.rsme);
            } else {
                setValue('modelName', '');
                setValue('modelType', '');
                setValue('sensor', null);
                setValue('field', null);
                setValue('algorithm', '');
                setValue('accuracy', '');
                setValue('rsme', '');
                setValue('file', null);
            }
        } else {
            reset();
        }
    }, [open, data, sensors, fields]);




    const getPolygonArea = (data: any) => {
        let cords: any = data.features[0].geometry.coordinates[0];
        setCoordinates(cords);
        console.log("Coordinates", cords);
        const center = turf.center(data);
        const [lng, lat] = center.geometry.coordinates;
        console.log("Center Latitude:", lat, "Center Longitude:", lng);
        //setValue("latitude", lat);
        //setValue("longitude", lng);
        const area = turf.area(data);
        //setValue('area', Math.round(area * 100) / 100);
        //setValue('areaUnit', 'sq.m');
    };

    useEffect(() => {
        console.log("mapRef ------------------>", mapRef.current);
        if (mapboxRef.current && mapRef.current == null) {
            console.log("mapboxRef", mapboxRef.current);
            mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_KEY}`;



            mapRef.current = new mapboxgl.Map({
                container: mapboxRef.current,
                style: 'mapbox://styles/rajsekhardas/cm2edf6w200tt01pi34159jq8', //mapbox://styles/mapbox/dark-v11
                center: [-74.5, 40],//[88.32393090000001, 27.3516407],
                zoom: 20
            });
            mapRef.current.on('load', () => {
                const draw = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: {},//{ polygon: true, trash: true }
                    defaultMode: 'draw_polygon'//(field && field.boundary)?'simple_select':'draw_polygon'
                });
                mapRef.current.addControl(draw);
                mapRef.current.on('draw.create', updateArea);
                mapRef.current.on('draw.delete', updateArea);
                mapRef.current.on('draw.update', updateArea);

                function updateArea(e: any) {
                    const data = draw.getAll();

                    if (data.features.length > 0) {

                        getPolygonArea(data);
                        const area = turf.area(data);
                        setRoundedArea(Math.round(area * 100) / 100);
                    } else {
                        setRoundedArea(0);
                        //if (e.type !== 'draw.delete') alert('Click the map to draw a polygon.');
                    }
                }



            });


        }
    }, [mapboxRef.current, isOpen]);

    return (
        <Modal show={open} onHide={close} >
            <Modal.Header closeButton>
                <Modal.Title>Edit Model Params</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {sensors && fields &&
                    <div className="relative bg-white rounded-lg max-h-screen overflow-auto min-h-[550px]">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid grid-cols-2 gap-3">
                                

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Sensor</label>
                                    <Controller
                                        control={control}
                                        name="sensor"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select
                                                isMulti={false}
                                                value={field.value}
                                                onChange={field.onChange}
                                                options={sensors ? sensors : []}
                                            />
                                        )}
                                    />
                                    {errors.sensor && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Field</label>
                                    <Controller
                                        control={control}
                                        name="field"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Select
                                                isMulti={false}
                                                value={field.value}
                                                onChange={field.onChange}
                                                options={fields ? fields : []}
                                            />
                                        )}
                                    />
                                    {errors.field && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Model Name</label>
                                    <input
                                        type="text"
                                        {...register("modelName", { required: true })}
                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                    />
                                    {errors.modelName && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Model Type</label>
                                    <input
                                        type="text"
                                        {...register("modelType", { required: true })}
                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                    />
                                    {errors.modelType && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">Algorithm</label>
                                    <input
                                        type="text"
                                        {...register("algorithm", { required: true })}
                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                    />
                                    {errors.algorithm && <p className="text-red-600 text-sm">This field is required</p>}
                                </div>

                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">RSME</label>
                                    <input
                                        type="text"
                                        {...register("rsme", { required: true })}
                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                    />
                                </div>
                            
                    
                               
                                <div className="mb-2">
                                    <label className="block text-sm font-medium">Accuracy</label>
                                    <input
                                        type="text"
                                        {...register("accuracy", { required: true })}
                                        className="border border-gray-300 rounded-md p-2 mb-2 w-full m-0"
                                    />
                                </div>
                                <div className="mb-2 col-span-2">
                                    <label className="block text-sm font-medium">Model File</label>
                                    <input
                                        type="file"
                                        accept=".pkl"
                                        {...register("file", { required: false })}
                                        className="border border-gray-300 rounded-md mb-2 w-full m-0"
                                    />
                                </div>
                                
                            </div>

                     

                            <div className="flex justify-end">
                            <button type="submit" className="btn btn-sm btn-primary font-semibold" >
                                Submit
                            </button>
                            </div>
                        </form>
                    </div>
                }
            </Modal.Body>
        </Modal>

    );
};

export default EditModelParamsModal;