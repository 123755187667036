import React, { useEffect, useState, PureComponent } from 'react';
import Select from 'react-select';
import { Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Line, Legend, Area } from 'recharts';
import moment from 'moment';
import { DATA } from '../../_config';
import LeftPanel from '../../../../components/leftPanel/LeftPanel';
import Header from '../../../../components/header/Header';
import { API_BASE_URL, MAPAPI_BASE_URL } from '../../../../_config/site_urls';
import { useAppLoader } from '../../../../_common/hooks/common/appLoaderHook';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFarmsApi } from '../../../../_common/hooks/api/farmApiHook';
import { fr } from 'date-fns/locale';
import RightPanel from '../../components/rightPannel/rightPanel';
import { GRAPH_COLORS, URLS } from '../../../../_config';
import { replaceUrlParams } from '../../../../_common/functions';
import Loader from '../../../../components/loader';
import TitleBar from '../../../../components/TopBar/TitleBar';

const Analytics: React.FC = () => {
    const [indexType, setIndexType] = useState<any>({ value: 'ndvi', label: 'NDVI' });
    const [fromDate, setFromDate] = useState<any>(moment().add(-7, 'days').format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState<any>(moment().format('YYYY-MM-DD'));
    const { showLoader, hideLoader } = useAppLoader();
    const [graphData, setGraphData] = useState<any>(null);
    const [historyData, setHistoryData] = useState<any>(null);
    const [latestData, setLatestData] = useState<any>([0, 0, 0, 0, 0]);

    const farmsApi = useFarmsApi()
    const location = useLocation();
    const params: any = useParams();
    const [fields, setFields] = useState<any[] | null>(null);
    const [field, setField] = useState<any>(null);

    const [alerts, setAlerts] = useState<any>(null);
    const [modelAlerts, setModelAlerts] = useState<any>(null);
    const [ruleAlerts, setRuleAlerts] = useState<any>(null);

    const [forecast, setForecast] = useState<any>(null);

    const [fakeForecast, setFakeForecast] = useState<any>({
        '672b4f0ce38e0d9bbb0ba803':{
            "2024-11-07": [
                {
                    "sensor_name": "Soil Temperature",
                    "average_prediction": 22.369089,
                    "average_upper_bound": 25.424348783680582,
                    "average_lower_bound": 19.54206398037539
                },
                {
                    "sensor_name": "Soil Moisture",
                    "average_prediction": 30.167487,
                    "average_upper_bound": 31.524421589732672,
                    "average_lower_bound": 29.958072952034872
                },
                {
                    "sensor_name": "Soil EC",
                    "average_prediction": 29.757045,
                    "average_upper_bound": 30.83005473966386,
                    "average_lower_bound": 26.5334877692857
                },
                {
                    "sensor_name": "Soil pH",
                    "average_prediction": 7.91127,
                    "average_upper_bound": 8.526965950579205,
                    "average_lower_bound": 7.146527629517978
                },
                {
                    "sensor_name": "Soil Salinity",
                    "average_prediction": 17.731094,
                    "average_upper_bound": 19.061755013789437,
                    "average_lower_bound": 17.4399778622942
                }
            ],
            "2024-11-08": [
                {
                    "sensor_name": "Soil Temperature",
                    "average_prediction": 21.48342,
                    "average_upper_bound": 22.542221886371117,
                    "average_lower_bound": 16.067410130075462
                },
                {
                    "sensor_name": "Soil Moisture",
                    "average_prediction": 28.973053,
                    "average_upper_bound": 29.790255169934937,
                    "average_lower_bound": 28.065504678702823
                },
                {
                    "sensor_name": "Soil EC",
                    "average_prediction": 28.578862,
                    "average_upper_bound": 30.969927478205427,
                    "average_lower_bound": 28.15733041727662
                },
                {
                    "sensor_name": "Soil pH",
                    "average_prediction": 7.598035,
                    "average_upper_bound": 7.756862530573537,
                    "average_lower_bound": 6.215375371103671
                },
                {
                    "sensor_name": "Soil Salinity",
                    "average_prediction": 17.02906,
                    "average_upper_bound": 18.013360034473084,
                    "average_lower_bound": 16.37014490853835
                }
            ],
            "2024-11-09": [
                {
                    "sensor_name": "Soil Temperature",
                    "average_prediction": 20.851702,
                    "average_upper_bound": 26.50896121756479,
                    "average_lower_bound": 18.92298264273806
                },
                {
                    "sensor_name": "Soil Moisture",
                    "average_prediction": 28.121175,
                    "average_upper_bound": 28.645087503566646,
                    "average_lower_bound": 27.575234489612082
                },
                {
                    "sensor_name": "Soil EC",
                    "average_prediction": 27.738566,
                    "average_upper_bound": 28.847960862077464,
                    "average_lower_bound": 25.692051044825426
                },
                {
                    "sensor_name": "Soil pH",
                    "average_prediction": 7.374588,
                    "average_upper_bound": 8.416848038214928,
                    "average_lower_bound": 7.180462396901773
                },
                {
                    "sensor_name": "Soil Salinity",
                    "average_prediction": 16.528376,
                    "average_upper_bound": 18.00243698202719,
                    "average_lower_bound": 16.206054959328974
                }
            ],
            "2024-11-10": [
                {
                    "sensor_name": "Soil Temperature",
                    "average_prediction": 20.912135,
                    "average_upper_bound": 25.39528438203429,
                    "average_lower_bound": 20.35086464343465
                },
                {
                    "sensor_name": "Soil Moisture",
                    "average_prediction": 28.202676,
                    "average_upper_bound": 29.095197877937714,
                    "average_lower_bound": 27.37574293177598
                },
                {
                    "sensor_name": "Soil EC",
                    "average_prediction": 27.818958,
                    "average_upper_bound": 29.472787218497167,
                    "average_lower_bound": 25.852321732049692
                },
                {
                    "sensor_name": "Soil pH",
                    "average_prediction": 7.395962,
                    "average_upper_bound": 8.785921282290044,
                    "average_lower_bound": 7.247515012719895
                },
                {
                    "sensor_name": "Soil Salinity",
                    "average_prediction": 16.576279,
                    "average_upper_bound": 17.987267228581953,
                    "average_lower_bound": 16.171504346286476
                }
            ],
            "2024-11-11": [
                {
                    "sensor_name": "Soil Temperature",
                    "average_prediction": 21.794114,
                    "average_upper_bound": 25.911653195380552,
                    "average_lower_bound": 21.69839207210488
                },
                {
                    "sensor_name": "Soil Moisture",
                    "average_prediction": 29.392138,
                    "average_upper_bound": 29.693609014287194,
                    "average_lower_bound": 28.27585622871734
                },
                {
                    "sensor_name": "Soil EC",
                    "average_prediction": 28.992237,
                    "average_upper_bound": 30.642291615232647,
                    "average_lower_bound": 25.387473169323794
                },
                {
                    "sensor_name": "Soil pH",
                    "average_prediction": 7.70789,
                    "average_upper_bound": 9.088203400880198,
                    "average_lower_bound": 7.320344527983186
                },
                {
                    "sensor_name": "Soil Salinity",
                    "average_prediction": 17.275392,
                    "average_upper_bound": 18.586227578881203,
                    "average_lower_bound": 17.050731539904397
                }
            ],
            "2024-11-12": [
                {
                    "sensor_name": "Soil Temperature",
                    "average_prediction": 21.355908,
                    "average_upper_bound": 25.582125202329603,
                    "average_lower_bound": 21.263017782484432
                },
                {
                    "sensor_name": "Soil Moisture",
                    "average_prediction": 28.801142,
                    "average_upper_bound": 29.81299621393648,
                    "average_lower_bound": 28.55241576578933
                },
                {
                    "sensor_name": "Soil EC",
                    "average_prediction": 28.409283,
                    "average_upper_bound": 29.86167762040199,
                    "average_lower_bound": 26.116972930846508
                },
                {
                    "sensor_name": "Soil pH",
                    "average_prediction": 7.552925,
                    "average_upper_bound": 8.518510004279488,
                    "average_lower_bound": 6.796832747652293
                },
                {
                    "sensor_name": "Soil Salinity",
                    "average_prediction": 16.928061,
                    "average_upper_bound": 17.26473739812273,
                    "average_lower_bound": 15.55484415599853
                }
            ]
        },
        '672b4fc1e38e0d9bbb0ba848':{
            "2024-11-07": [
                {
                    "sensor_name": "Soil Temperature",
                    "average_prediction": 18.945205,
                    "average_upper_bound": 19.793826047735923,
                    "average_lower_bound": 13.553491782722654
                },
                {
                    "sensor_name": "Soil Moisture",
                    "average_prediction": 7.675237,
                    "average_upper_bound": 8.770767970406068,
                    "average_lower_bound": 7.450580330457043
                },
                {
                    "sensor_name": "Soil EC",
                    "average_upper_bound": null,
                    "average_lower_bound": null
                },
                {
                    "sensor_name": "Soil pH",
                    "average_prediction": 6.538525,
                    "average_upper_bound": 7.812039575161845,
                    "average_lower_bound": 6.206847996056073
                },
                {
                    "sensor_name": "Soil Salinity",
                    "average_upper_bound": null,
                    "average_lower_bound": null
                }
            ],
            "2024-11-08": [
                {
                    "sensor_name": "Soil Temperature",
                    "average_prediction": 19.127784,
                    "average_upper_bound": 25.07906930464587,
                    "average_lower_bound": 18.79523695518125
                },
                {
                    "sensor_name": "Soil Moisture",
                    "average_prediction": 7.749205,
                    "average_upper_bound": 8.491677229638679,
                    "average_lower_bound": 7.109989138163583
                },
                {
                    "sensor_name": "Soil EC",
                    "average_upper_bound": null,
                    "average_lower_bound": null
                },
                {
                    "sensor_name": "Soil pH",
                    "average_prediction": 6.601538,
                    "average_upper_bound": 6.895162727995428,
                    "average_lower_bound": 5.476907740868368
                },
                {
                    "sensor_name": "Soil Salinity",
                    "average_upper_bound": null,
                    "average_lower_bound": null
                }
            ],
            "2024-11-12": [
                {
                    "sensor_name": "Soil Temperature",
                    "average_upper_bound": null,
                    "average_lower_bound": null
                },
                {
                    "sensor_name": "Soil Moisture",
                    "average_upper_bound": null,
                    "average_lower_bound": null
                },
                {
                    "sensor_name": "Soil EC",
                    "average_upper_bound": null,
                    "average_lower_bound": null
                },
                {
                    "sensor_name": "Soil pH",
                    "average_upper_bound": null,
                    "average_lower_bound": null
                },
                {
                    "sensor_name": "Soil Salinity",
                    "average_upper_bound": null,
                    "average_lower_bound": null
                }
            ]
        }
    })

    // Example usage

    const [dataTypes, setDataTypes] = useState([
        { value: 'Soil EC', label: 'Soil EC' },
        { value: 'Soil pH', label: 'Soil pH' },
        { value: 'Soil Moisture', label: 'Soil Moisture' },
        { value: 'Soil Salinity', label: 'Soil Salinity' },
        { value: 'Soil Temperature', label: 'Soil Temperature' },
        { value: 'Solar Radiation', label: 'Solar Radiation' },
        { value: 'Wind Direction', label: 'Wind Direction' },
        { value: 'Wind speed', label: 'Wind speed' }
    ]);

    const [indexes, setIndexes] = useState<any>([
        { value: 'ndvi', label: 'NDVI' },
    ]);

    const [data, setData] = useState(DATA);

    const getFields = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + "/fields");
            const data = await response.json();
            setFields(data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    };

    const getFieldDeatils = async () => {
        setGraphData(null);
        try {
            const response = await fetch(MAPAPI_BASE_URL + "/fields/" + params.id);
            const data = await response.json();
            setField(data);
            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    }

    class CustomizedXAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={10} fontSize={10} textAnchor="end" fill="#222222" transform="rotate(0)">
                        {moment(payload.value).format('DD-MMM')}
                    </text>
                </g>
            );
        }
    }
    class CustomizedYAxisTick extends PureComponent {
        render() {
            const { x, y, stroke, payload }: any = this.props;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={0} textAnchor="end" fontSize={11} fill="#222222" transform="rotate(0)">
                        {payload.value.toFixed(2)}
                    </text>
                </g>
            );
        }
    }
    const renderLegend = (props: any) => {
        const { payload } = props;
        return (
            <div className="flex justify-center gap-2" >
                {
                    payload.map((entry: any, index: any) => {
                        let lable = entry.value;
                        let icon: any = '';
                        switch (entry.value) {
                            case 'ndvi-bar':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6 21H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z"></path></svg>
                                lable = 'NDVI'
                                break;
                            case 'ndvi':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z"></path></svg>
                                lable = 'NDVI'
                                break;
                            case 'air_temp':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z"></path></svg>
                                lable = 'Temperature'
                                break;
                            case 'humidity':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z"></path></svg>
                                lable = 'Humidity'
                                break;
                            case 'soil_moisture':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 12.75a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H5.25a.75.75 0 0 1-.75-.75Z"></path></svg>
                                lable = 'Moisture'
                                break;
                            case 'humidity':
                                icon = <svg stroke={`${entry.color}`} fill={`${entry.color}`} strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M112 476h160v72H112zm320 0h160v72H432zm320 0h160v72H752z"></path></svg>
                                lable = 'Humidity'
                                break;
                            default:
                                lable = entry.value
                        }
                        return (
                            <div className="flex items-center gap-1" key={`item-${index}`}>
                                {icon}
                                <p className="m-0 font-semibold text-xs" style={{ color: '#555b64' }}>{lable}</p>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    const CustomizedDot = (props: any) => {
        const { cx, cy, stroke, payload, value, dataKey } = props;
        if (payload.alerts && payload.alerts[dataKey]) {
            return (
                <svg x={cx - 5} y={cy - 5} viewBox="0 0 24 24" height="12px" width="10px" xmlns="http://www.w3.org/2000/svg">
                    <circle fill='#dc2626' cx="12" cy="12" r="10" strokeWidth="4" stroke='#ffffff'></circle>
                </svg>
            );
        }
        if (payload.alert_level == "Critical" || payload.alert_level == "danger") {
            return (
                <svg x={cx - 10} y={cy - 20} stroke="alert" fill="url(#alert)" strokeWidth="0" viewBox="0 0 24 24" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <radialGradient id="alert" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                            <stop offset="30%" stopColor="#ef4444" />
                            <stop offset="100%" stopColor="#b91c1c" />
                        </radialGradient>
                    </defs>
                    <path d="M6 8a6 6 0 1 1 12 0v2.917c0 .703.228 1.387.65 1.95L20.7 15.6a1.5 1.5 0 0 1-1.2 2.4h-15a1.5 1.5 0 0 1-1.2-2.4l2.05-2.733a3.25 3.25 0 0 0 .65-1.95Zm6 13.5A3.502 3.502 0 0 1 8.645 19h6.71A3.502 3.502 0 0 1 12 21.5Z"></path></svg>
            );
        }
        if (payload.alert_level == "warning") {
            return (
                <svg x={cx - 10} y={cy - 20} stroke="alert" fill="url(#warning)" strokeWidth="0" viewBox="0 0 512 512" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <radialGradient id="warning" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                            <stop offset="30%" stopColor="#fbbf24" />
                            <stop offset="100%" stopColor="#d97706" />
                        </radialGradient>
                    </defs>
                    <path d="M228.9 79.9L51.8 403.1C40.6 423.3 55.5 448 78.9 448h354.3c23.3 0 38.2-24.7 27.1-44.9L283.1 79.9c-11.7-21.2-42.5-21.2-54.2 0zM273.6 214L270 336h-28l-3.6-122h35.2zM256 402.4c-10.7 0-19.1-8.1-19.1-18.4s8.4-18.4 19.1-18.4 19.1 8.1 19.1 18.4-8.4 18.4-19.1 18.4z"></path></svg>
            );
        }

        return (null);
    };

    const getFieldHistory = async () => {
        try {
            farmsApi.getFieldsHistory({
                urlParams: { field_or_farm_id: params.id },
                _from: fromDate + ' 00:00:00',
                _to: toDate + ' 23:59:59',
                // plot_id: '',
            }, (message: any, resp: any) => {
                var _ndvidata = resp[0].field_index_data;

                resp[0]?.crops.map((crop: any) => {
                    let data: any = {};
                    crop.sensors.map((sensor: any) => {
                        let dt = moment(sensor.timestamp).format('YYYY-MM-DD');
                        if (Object.keys(data).indexOf(dt) === -1) {
                            data[dt] = {};
                        }
                        if (Object.keys(data[dt]).indexOf(sensor.sensor_name) === -1) {
                            data[dt][sensor.sensor_name] = [];
                        }
                        data[dt][sensor.sensor_name].push(sensor);
                    });

                    crop.data = data;
                    crop.label = crop.plot_name;
                    crop.value = crop.plot_id;

                    let dayWiseData: any = [];
                    Object.keys(data).map((dt: any) => {
                        let el: any = { date: dt }
                        Object.keys(data[dt]).map((key: any) => {
                            el[key] = data[dt][key]?.[0]?.value || 0;
                        });
                        dayWiseData.push(el);
                    });


                    _ndvidata.map((ndvidata: any) => {
                        let _dt = moment(ndvidata.timestamp).format('YYYY-MM-DD');

                        if (moment(ndvidata.timestamp).isBetween(moment(fromDate), moment(toDate), undefined, '[]')) {

                        //if (moment(ndvidata.timestamp) <= moment(toDate) && moment(ndvidata.timestamp) >= moment(fromDate)) {

                            let placedData = false;
                            dayWiseData.map((el: any) => {
                                let dt = moment(el.date).format('YYYY-MM-DD');
                                if (_dt == dt) {
                                    placedData = true;
                                    el['ndvi'] = ndvidata.index_value_sd;
                                    placedData = true
                                }
                            });
                            if (!placedData) {
                                let el: any = { date: _dt }
                                el['ndvi'] = ndvidata.index_value_sd;
                                dayWiseData.push(el);
                            }
                        } else {
                            console.log('Data not in range', ndvidata.timestamp, fromDate, toDate);
                        }

                    });

                    crop.dayWiseData = dayWiseData.sort((a: any, b: any) => {
                        return new Date(a.date).getTime() - new Date(b.date).getTime();
                    });

                    if (params.id == '671f3aa0842b16f4ad6e4a4f' || params.id == '672b5050e38e0d9bbb0ba886' || true) {
                        getForecast().then((forecast: any) => {
                            Object.keys(forecast).map((fdt: any) => {
                                resp[0].crops.map((crop: any) => {
                                    let pushed: boolean = false;
                                    crop.dayWiseData.map((d: any) => {
                                        if (d.date == fdt) {
                                            forecast[fdt].map((f: any) => {
                                                d[f.sensor_name + ' forecast avg'] = f.average_prediction;
                                                d[f.sensor_name + ' forecast low'] = f.average_lower_bound;
                                                d[f.sensor_name + ' forecast high'] = f.average_upper_bound;
                                                d[f.sensor_name + ' forecast lowHigh'] = [f.average_lower_bound, f.average_upper_bound];
                                            });
                                            pushed = true;
                                        }
                                    });
                                    if (!pushed) {
                                        let el: any = { date: fdt }
                                        forecast[fdt].map((f: any) => {
                                            el[f.sensor_name + ' forecast avg'] = f.average_prediction;
                                            el[f.sensor_name + ' forecast low'] = f.average_lower_bound;
                                            el[f.sensor_name + ' forecast high'] = f.average_upper_bound;
                                            el[f.sensor_name + ' forecast lowHigh'] = [f.average_lower_bound, f.average_upper_bound];
                                        });
                                        crop.dayWiseData.push(el);
                                    }

                                    return crop;
                                });
                            });

                            resp[0].crops.map((crop: any) => {
                                crop.dayWiseData = crop.dayWiseData.sort((a: any, b: any) => {
                                    return new Date(a.date).getTime() - new Date(b.date).getTime();
                                });
                                return crop;
                            });

                            setHistoryData(resp[0]);
                        });
                    } else {
                        getFakeForecast(resp[0]).then((forecast: any) => {
                            console.log('Fake Forecast', forecast);
                            Object.keys(forecast).map((fdt: any) => {
                                resp[0].crops.map((crop: any) => {
                                    let pushed: boolean = false;
                                    crop.dayWiseData.map((d: any) => {
                                        if (d.date == fdt) {
                                            forecast[fdt].map((f: any) => {
                                                d[f.sensor_name + ' forecast avg'] = f.average_prediction;
                                                d[f.sensor_name + ' forecast low'] = f.average_lower_bound;
                                                d[f.sensor_name + ' forecast high'] = f.average_upper_bound;
                                                d[f.sensor_name + ' forecast lowHigh'] = [f.average_lower_bound, f.average_upper_bound];
                                            });
                                            pushed = true;
                                        }
                                    });
                                    if (!pushed) {
                                        let el: any = { date: fdt }
                                        forecast[fdt].map((f: any) => {
                                            el[f.sensor_name + ' forecast avg'] = f.average_prediction;
                                            el[f.sensor_name + ' forecast low'] = f.average_lower_bound;
                                            el[f.sensor_name + ' forecast high'] = f.average_upper_bound;
                                            el[f.sensor_name + ' forecast lowHigh'] = [f.average_lower_bound, f.average_upper_bound];
                                        });
                                        crop.dayWiseData.push(el);
                                    }

                                    return crop;
                                });
                            });

                            resp[0].crops.map((crop: any) => {
                                crop.dayWiseData = crop.dayWiseData.sort((a: any, b: any) => {
                                    return new Date(a.date).getTime() - new Date(b.date).getTime();
                                });
                                return crop;
                            });

                            setHistoryData(resp[0]);
                        });
                    }

                });
            }, (message: any) => {
                hideLoader()
            })

        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    }

    const getFieldNewHistory = async () => {
        showLoader();
        try {

            const response = await fetch(MAPAPI_BASE_URL + `/fields/${params.id}/daily-history?${new URLSearchParams({
                _from: fromDate + ' 00:00:00',
                _to: toDate + ' 23:59:59'
            })}`);
            const data: any = await response.json();

            console.log('new history data=>', data)


            let dateWiseData: any = [];

            data[0].sensors.map((sensor: any) => {
                sensor.history.map((h: any) => {
                    let dt = moment(h.date).format('YYYY-MM-DD');
                    if (dateWiseData[dt] === undefined) {
                        dateWiseData[dt] = {};
                    }
                    dateWiseData[dt][sensor.sensor_name] = h.value;
                });
            });

            var _ndvidata = data[0]?.field_index_data;
            if (_ndvidata) {
                _ndvidata.map((ndvidata: any) => {
                    let dt = moment(ndvidata.timestamp).format('YYYY-MM-DD');
                    if (dateWiseData[dt] === undefined) {
                        dateWiseData[dt] = {};
                    }
                    dateWiseData[dt]['ndvi'] = ndvidata.index_value_sd;
                });

                /* 
                if (moment(ndvidata.timestamp).isBetween(moment(fromDate), moment(toDate), undefined, '[]')) {
                        
                    }
                */

               
            } 
            /*dateWiseData = dateWiseData.filter((d: any) => moment(d.date).isBetween(moment(fromDate), moment(toDate), undefined, '[]'));*/
            getForecast().then((forecast: any) => {
                Object.keys(forecast).map((fdt: any) => {
                    if(dateWiseData[fdt] === undefined){
                        dateWiseData[fdt] = {};
                    }
                    forecast[fdt].map((f: any) => {
                        dateWiseData[fdt][f.sensor_name + ' forecast'] = f.average_prediction;
                        dateWiseData[fdt][f.sensor_name + ' forecast low'] = f.average_lower_bound;
                        dateWiseData[fdt][f.sensor_name + ' forecast high'] = f.average_upper_bound;
                        dateWiseData[fdt][f.sensor_name + ' forecast lowHigh'] = [f.average_lower_bound, f.average_upper_bound];
                    });
                    
                });
                setHistoryData(dateWiseData);
            });
            
            

            hideLoader();
        } catch (error) {
            hideLoader();
            console.error("Error fetching data:", error);
        }
    }

    const getFieldCropAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/alerts?${new URLSearchParams({
                field_or_farm_id: params.id
            })}`);
            const data: any = await response.json();
            setAlerts(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getFieldModelAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/model-alerts?${new URLSearchParams({
                field_or_farm_id: params.id
            })}`);
            const data: any = await response.json();
            setModelAlerts(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const getFieldRuleAlerts = async () => {
        try {
            const response = await fetch(MAPAPI_BASE_URL + `/sensors/rule-alerts?${new URLSearchParams({
                field_or_farm_id: params.id
            })}`);
            const data: any = await response.json();
            setRuleAlerts(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    const changeFromDate = (e: any) => {
        setFromDate(e.target.value);
    }
    const changeToDate = (e: any) => {
        setToDate(e.target.value);
    }


    const getLatestvalue = (key: any) => {
        let val: any = 0;
        if (graphData) {
            graphData.map((d: any) => {
                if (d[key]) {
                    val = d[key];
                }
            });
        }
        if (val) { val = val.toFixed(2) }
        return val
    }

    const getFakeForecast = async (data: any) => {
        return new Promise(async (resolve, reject) => {

            let LS_forecast: any = localStorage.getItem('forecast');
            let LS_F_forecast:any = null;
            if(LS_forecast && LS_forecast[params.id]){
                LS_F_forecast = JSON.parse(LS_forecast)[params.id];
            } else {
                LS_F_forecast = fakeForecast[params.id];
            }            

            let _forecast: any = {};
            let sensors: any = {
                'Soil Temperature': { diff: 2, rand: 2 },
                'Soil Moisture': { diff: 0.5, rand: 0.5},
                'Soil EC': { diff: 1, rand:2},
                'Soil pH': { diff: 0.5, rand: 0.5 },
                'Soil Salinity': { diff: 0.5, rand: 0.5 }
            }
            data.crops[0].dayWiseData.map((d: any) => {
                if (!_forecast[d.date]) {
                    _forecast[d.date] = [];
                }

                let dt = d.date;
                Object.keys(sensors).map((sensor: any) => {
                    let diff = sensors[sensor].diff;
                    let rand = sensors[sensor].rand;
                    let u: any = d[sensor] + diff + (Math.random() * rand);
                    let l: any = d[sensor] - diff - (Math.random() * rand);
                    let r: any = Math.floor(Math.random() * 3) - 1;
                    _forecast[dt].push({
                        sensor_name: sensor,
                        average_prediction: d[sensor],
                        average_upper_bound: u + (r * diff),
                        average_lower_bound: l + (r * diff)
                    });
                });
            });
            resolve(_forecast);
        });
    }

    const getForecast = async () => {
        return new Promise(async (resolve, reject) => {
            const response = await fetch(MAPAPI_BASE_URL + `/fields/${params.id}/daily-forecast?${new URLSearchParams({
                _from: moment(fromDate).format('YYYY-MM-DD 00:00:00'),
                _to: moment().add(10, 'days').format('YYYY-MM-DD 23:59:59')
            })}`);

            const data = await response.json();
            let _forecast: any = {};
            data.forEach((d: any) => {
                let dt = moment(d.date).format('YYYY-MM-DD');
                if (!_forecast[dt]) {
                    _forecast[dt] = [];
                }
                _forecast[dt].push(d);
            });
            console.log('forecast--->', _forecast);
            setForecast(_forecast);
            resolve(_forecast);
        });
    };


    useEffect(() => {
        if (fromDate && toDate && field) {
            getFieldNewHistory();
            getFieldCropAlerts();
            getFieldModelAlerts();
            getFieldRuleAlerts();
        }
    }, [toDate, fromDate, field]);

    useEffect(() => {
        if (historyData) {
            let __data:any =Object.keys(historyData).map((key: any) => {
                return {date: key, ...historyData[key]};
            })
            setGraphData(__data.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()).filter((d: any) => moment(d.date).isBetween(moment(fromDate), moment(toDate).add(10,'days'), undefined, '[]')));
        }
    }, [historyData]);

    useEffect(() => {
        getFields();
        getFieldDeatils();
    }, [params.id]);

    useEffect(() => {
        if (graphData && alerts && modelAlerts && ruleAlerts) {

            alerts.map((alert: any) => {
                let dt: any = moment(alert.timestamp).format('YYYY-MM-DD');
                if (graphData.filter((d: any) => d.date == dt).length > 0) {
                    graphData.map((d: any) => {
                        if (d.date == dt) {
                            if (!d.alerts) {
                                d['alerts'] = {};
                            }
                            if (!d.alerts[alert.sensor.name]) {
                                d.alerts[alert.sensor.name] = { crop: [] };
                            }
                            d.alerts[alert.sensor.name].crop.push(alert);
                        }
                        return d;
                    });
                }
            });

            modelAlerts.map((alert: any) => {
                let dt: any = moment(alert.timestamp).format('YYYY-MM-DD');
                if (graphData.filter((d: any) => d.date == dt).length > 0) {
                    graphData.map((d: any) => {
                        if (d.date == dt) {
                            if (!d.alerts) {
                                d['alerts'] = {};
                            }
                            if (!d.alerts[alert.sensor.name]) {
                                d.alerts[alert.sensor.name] = { model: [] };
                            } else if (!d.alerts[alert.sensor.name].model) {
                                d.alerts[alert.sensor.name].model = [];
                            }
                            d.alerts[alert.sensor.name].model.push(alert);
                        }
                        return d;
                    });
                }
            });

            ruleAlerts.map((alert: any) => {
                let dt: any = moment(alert.timestamp).format('YYYY-MM-DD');
                if (graphData.filter((d: any) => d.date == dt).length > 0) {
                    graphData.map((d: any) => {
                        if (d.date == dt) {
                            if (!d.alerts) {
                                d['alerts'] = {};
                            }
                            if (!d.alerts[alert.sensor.name]) {
                                d.alerts[alert.sensor.name] = { rule: [] };
                            } else if (!d.alerts[alert.sensor.name].rule) {
                                d.alerts[alert.sensor.name].rule = [];
                            }
                            d.alerts[alert.sensor.name].rule.push({ ...alert, from: 'rule' });
                        }
                        return d;
                    });
                }
            });

            console.log('Graph Data', graphData);
        }
    }, [graphData, alerts, modelAlerts, ruleAlerts]);

    return (
        <div className='h-screen'>
            <Header></Header>
            <LeftPanel></LeftPanel>
            <div className="main-content">
                <div className="page-content pb-0">
                    <div className="container-fluid">
                        <TitleBar title={`Analytics — ${field?.name}`} breadcrumbs={{ 'Analytics': '#', }} />
                        <div className="white-box-card mb-1 h-100 p-0 overflow-hidden" data-aos="fade-up">
                            <div className="flex w-full h-full st-height">

                                <div className='flex-grow flex flex-col st-height'>
                                    <div>
                                        <div className="flex items-center gap-3  p-3 bg-slate-200">
                                            <div>
                                                <label className='text-xs text-slate-500'>Date Range</label>
                                                <div className='flex gap-3 items-center'>
                                                    <input type="date" value={fromDate} className="border border-gray-300 rounded-md p-2 m-0" onChange={changeFromDate} />
                                                    <span className='text-slate-500'>to</span>
                                                    <input type="date" min={fromDate} value={toDate} className="border border-gray-300 rounded-md p-2 m-0" onChange={changeToDate} />
                                                </div>
                                            </div>
                                            <div>
                                                <label className='text-xs text-slate-500'>Index</label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isClearable={false}
                                                    value={indexType}
                                                    isSearchable={false}
                                                    onChange={setIndexType}
                                                    name="color"
                                                    options={indexes}

                                                />
                                            </div>
                                            <div className='d-none'>
                                                <label className='text-xs text-slate-500'>Crop</label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isClearable={false}
                                                    //value={indexType}
                                                    isSearchable={false}
                                                    //onChange={setIndexType}
                                                    name="color"
                                                    options={[]}
                                                />
                                            </div>

                                            {/* <div>
                                        <Link to=""><button >Back</button></Link>
                                    </div> */}
                                        </div>
                                    </div>

                                    <div className='p-3 flex-grow overflow-auto' style={{ height: 'calc(100vh - 165px)' }}>

                                        <div className='mb-5'>
                                            <h2 className='text-xl font-semibold'>Soil Temperature,  {getLatestvalue('Soil Temperature')}<sup>o</sup>C</h2>
                                            <div className='w-full h-[360px] relative'>
                                                {!graphData &&
                                                    <div className='absolute flex items-center justify-center w-100 h-100'><Loader /></div>
                                                }
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <ComposedChart
                                                        width={500}
                                                        height={300}
                                                        data={graphData}
                                                        margin={{ top: 5, right: 25, left: 0, bottom: 30, }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="soilTempArea" x1="0%" x2="0%" y1="0%" y2="100%">
                                                                <stop offset="0%" stopColor={GRAPH_COLORS.SOIL_TEMP.LOW} />
                                                                <stop offset="100%" stopColor={GRAPH_COLORS.SOIL_TEMP.HIGH} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
                                                        <YAxis domain={['dataMin', 'dataMax ']} tick={<CustomizedYAxisTick />} />
                                                        <Tooltip
                                                            contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                                            labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                                            itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                                                            labelFormatter={(value: any) => moment(value).format('DD-MMM')}
                                                        />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="Soil Temperature forecast lowHigh"
                                                            stroke="none"
                                                            fill="url(#soilTempArea)"
                                                            connectNulls
                                                            dot={false}
                                                            activeDot={false}
                                                        />
                                                        <Line type="monotone" dataKey="Soil Temperature forecast low" connectNulls={true} stroke={GRAPH_COLORS.SOIL_TEMP.LOW} strokeWidth={3} strokeDasharray={"5 5"} dot={false} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil Temperature forecast high" connectNulls={true} stroke={GRAPH_COLORS.SOIL_TEMP.HIGH} strokeWidth={3} strokeDasharray={"2 2"} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil Temperature" connectNulls={true} stroke={GRAPH_COLORS.SOIL_TEMP.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="ndvi" stroke={GRAPH_COLORS.NDVI.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />
                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>

                                        <div className='mb-5'>
                                            <h2 className='text-xl font-semibold'>Soil Moisture,  {getLatestvalue('Soil Moisture')}%</h2>
                                            <div className='w-full h-[360px] relative'>
                                                {!graphData &&
                                                    <div className='absolute flex items-center justify-center w-100 h-100'><Loader /></div>
                                                }
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <ComposedChart
                                                        width={500}
                                                        height={300}
                                                        data={graphData}
                                                        margin={{ top: 5, right: 25, left: 0, bottom: 30, }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="soilMoistureArea" x1="0%" x2="0%" y1="0%" y2="100%">
                                                                <stop offset="0%" stopColor={GRAPH_COLORS.SOIL_MOISTURE.LOW} />
                                                                <stop offset="100%" stopColor={GRAPH_COLORS.SOIL_MOISTURE.HIGH} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
                                                        <YAxis domain={['dataMin-1', 'dataMax+1']} tick={<CustomizedYAxisTick />} orientation='left' />
                                                        {/* <YAxis dataKey={'humidity'} domain={['dataMin-1', 'dataMax+1']} tick={<CustomizedYAxisTick />} orientation='right' /> */}
                                                        <Tooltip
                                                            contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                                            labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                                            itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                                                            labelFormatter={(value: any) => moment(value).format('DD-MMM')}
                                                        />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="Soil Moisture forecast lowHigh"
                                                            stroke="none"
                                                            fill="url(#soilMoistureArea)"
                                                            connectNulls
                                                            dot={false}
                                                            activeDot={false}
                                                        />
                                                        <Line type="monotone" dataKey="Soil Moisture forecast low" connectNulls={true} stroke={GRAPH_COLORS.SOIL_MOISTURE.LOW} strokeWidth={3} strokeDasharray={"5 5"} dot={false} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil Moisture forecast high" connectNulls={true} stroke={GRAPH_COLORS.SOIL_MOISTURE.HIGH} strokeWidth={3} strokeDasharray={"2 2"} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil Moisture" connectNulls={true} stroke={GRAPH_COLORS.SOIL_MOISTURE.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        {/* <Bar dataKey="wind_speed" fill="url(#soilEc)" radius={5} activeBar={<Rectangle radius={5} />} /> */}
                                                        <Line type="monotone" dataKey="ndvi" stroke={GRAPH_COLORS.NDVI.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        {/* <Line type="monotone" dataKey="Min Soil EC" stroke="#057A55" strokeWidth={2} strokeDasharray="5 5" dot={false} activeDot={{ r: 4 }} /> */}
                                                        <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>

                                        <div className='mb-5'>
                                            <h2 className='text-xl font-semibold'>Soil pH,  {getLatestvalue('Soil pH')}</h2>
                                            <div className='w-full h-[360px] relative'>
                                                {!graphData &&
                                                    <div className='absolute flex items-center justify-center w-100 h-100'><Loader /></div>
                                                }
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <ComposedChart
                                                        width={500}
                                                        height={300}
                                                        data={graphData}
                                                        margin={{ top: 5, right: 25, left: 0, bottom: 30, }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="soilPhArea" x1="0%" x2="0%" y1="0%" y2="100%">
                                                                <stop offset="0%" stopColor={GRAPH_COLORS.SOIL_PH.LOW} />
                                                                <stop offset="100%" stopColor={GRAPH_COLORS.SOIL_PH.HIGH} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
                                                        <YAxis domain={['dataMin-1', 'dataMax+1']} tick={<CustomizedYAxisTick />} orientation='left' />
                                                        {/* <YAxis dataKey={'humidity'} domain={['dataMin-1', 'dataMax+1']} tick={<CustomizedYAxisTick />} orientation='right' /> */}
                                                        <Tooltip
                                                            contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                                            labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                                            itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                                                            labelFormatter={(value: any) => moment(value).format('DD-MMM')}
                                                        />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="Soil pH forecast lowHigh"
                                                            stroke="none"
                                                            fill="url(#soilPhArea)"
                                                            connectNulls
                                                            dot={false}
                                                            activeDot={false}
                                                        />
                                                        <Line type="monotone" dataKey="Soil pH forecast low" connectNulls={true} stroke={GRAPH_COLORS.SOIL_PH.LOW} strokeWidth={3} strokeDasharray={"5 5"} dot={false} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil pH forecast high" connectNulls={true} stroke={GRAPH_COLORS.SOIL_PH.HIGH} strokeWidth={3} strokeDasharray={"2 2"} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil pH" stroke={GRAPH_COLORS.SOIL_PH.PRIMARY} connectNulls={true} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        {/* <Bar dataKey="wind_speed" fill="url(#soilEc)" radius={5} activeBar={<Rectangle radius={5} />} /> */}
                                                        <Line type="monotone" dataKey="ndvi" stroke={GRAPH_COLORS.NDVI.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        {/* <Line type="monotone" dataKey="Min Soil EC" stroke="#057A55" strokeWidth={2} strokeDasharray="5 5" dot={false} activeDot={{ r: 4 }} /> */}
                                                        <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>








                                        <div className='mb-5'>
                                            <h2 className='text-xl font-semibold'>Soil Salinity,  {getLatestvalue('Soil Salinity')} μS/cm</h2>
                                            <div className='w-full h-[360px] relative'>
                                                {!graphData &&
                                                    <div className='absolute flex items-center justify-center w-100 h-100'><Loader /></div>
                                                }
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <ComposedChart
                                                        width={500}
                                                        height={300}
                                                        data={graphData}
                                                        margin={{ top: 5, right: 25, left: 0, bottom: 30, }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="soilSalinityArea" x1="0%" x2="0%" y1="0%" y2="100%">
                                                                <stop offset="0%" stopColor={GRAPH_COLORS.SOIL_SALINITY.LOW} />
                                                                <stop offset="100%" stopColor={GRAPH_COLORS.SOIL_SALINITY.HIGH} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
                                                        <YAxis domain={['dataMin-1', 'dataMax+1']} tick={<CustomizedYAxisTick />} orientation='left' />
                                                        {/* <YAxis dataKey={'humidity'} domain={['dataMin-1', 'dataMax+1']} tick={<CustomizedYAxisTick />} orientation='right' /> */}
                                                        <Tooltip
                                                            contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                                            labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                                            itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                                                            labelFormatter={(value: any) => moment(value).format('DD-MMM')}
                                                        />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="Soil Salinity forecast lowHigh"
                                                            stroke="none"
                                                            fill="url(#soilSalinityArea)"
                                                            connectNulls
                                                            dot={false}
                                                            activeDot={false}
                                                        />
                                                        <Line type="monotone" dataKey="Soil Salinity forecast low" connectNulls={true} stroke={GRAPH_COLORS.SOIL_SALINITY.LOW} strokeWidth={3} strokeDasharray={"5 5"} dot={false} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil Salinity forecast high" connectNulls={true} stroke={GRAPH_COLORS.SOIL_SALINITY.HIGH} strokeWidth={3} strokeDasharray={"2 2"} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil Salinity" connectNulls={true} stroke={GRAPH_COLORS.SOIL_SALINITY.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        {/* <Bar dataKey="wind_speed" fill="url(#soilEc)" radius={5} activeBar={<Rectangle radius={5} />} /> */}
                                                        <Line type="monotone" dataKey="ndvi" stroke={GRAPH_COLORS.NDVI.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        {/* <Line type="monotone" dataKey="Min Soil EC" stroke="#057A55" strokeWidth={2} strokeDasharray="5 5" dot={false} activeDot={{ r: 4 }} /> */}
                                                        <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>

                                        <div className='mb-5'>
                                            <h2 className='text-xl font-semibold'>Soil EC, {getLatestvalue('Soil EC')} μS/cm</h2>
                                            <div className='w-full h-[360px] relative'>
                                                {!graphData &&
                                                    <div className='absolute flex items-center justify-center w-100 h-100'><Loader /></div>
                                                }
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <ComposedChart
                                                        width={500}
                                                        height={300}
                                                        data={graphData}
                                                        margin={{ top: 5, right: 25, left: 0, bottom: 30, }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="soilEcArea" x1="0%" x2="0%" y1="0%" y2="100%">
                                                                <stop offset="0%" stopColor={GRAPH_COLORS.SOIL_EC.LOW} />
                                                                <stop offset="100%" stopColor={GRAPH_COLORS.SOIL_EC.HIGH} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
                                                        <YAxis domain={['dataMin-1', 'dataMax+1']} tick={<CustomizedYAxisTick />} orientation='left' />
                                                        {/* <YAxis dataKey={'humidity'} domain={['dataMin-1', 'dataMax+1']} tick={<CustomizedYAxisTick />} orientation='right' /> */}
                                                        <Tooltip
                                                            contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                                            labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                                            itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                                                            labelFormatter={(value: any) => moment(value).format('DD-MMM')}
                                                        />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="Soil EC forecast lowHigh"
                                                            stroke="none"
                                                            fill="url(#soilEcArea)"
                                                            connectNulls
                                                            dot={false}
                                                            activeDot={false}
                                                        />
                                                        <Line type="monotone" dataKey="Soil EC forecast low" connectNulls={true} stroke={GRAPH_COLORS.SOIL_EC.LOW} strokeWidth={3} strokeDasharray={"5 5"} dot={false} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil EC forecast high" connectNulls={true} stroke={GRAPH_COLORS.SOIL_EC.HIGH} strokeWidth={3} strokeDasharray={"2 2"} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Soil EC" connectNulls={true} stroke={GRAPH_COLORS.SOIL_EC.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />

                                                        {/* <Bar dataKey="wind_speed" fill="url(#soilEc)" radius={5} activeBar={<Rectangle radius={5} />} /> */}
                                                        <Line type="monotone" dataKey="ndvi" stroke={GRAPH_COLORS.NDVI.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div>


                                        {/* <div className='mb-5'>
                                            <h2 className='text-xl font-semibold'>Solar Radiation,  {getLatestvalue('Solar Radiation')} w/m<sup>2</sup></h2>
                                            <div className='w-full h-[360px] relative'>
                                                {!graphData &&
                                                    <div className='absolute flex items-center justify-center w-100 h-100'><Loader /></div>
                                                }
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <ComposedChart
                                                        width={500}
                                                        height={300}
                                                        data={graphData}
                                                        margin={{ top: 5, right: 25, left: 0, bottom: 30, }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="soilRadArea" x1="0%" x2="0%" y1="0%" y2="100%">
                                                                <stop offset="0%" stopColor={GRAPH_COLORS.SOLAR_RADIATION.LOW} />
                                                                <stop offset="100%" stopColor={GRAPH_COLORS.SOLAR_RADIATION.HIGH} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
                                                        <YAxis domain={['dataMin', 'dataMax ']} tick={<CustomizedYAxisTick />} />
                                                        <Tooltip
                                                            contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                                            labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                                            itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                                                            labelFormatter={(value: any) => moment(value).format('DD-MMM')}
                                                        />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="Solar Radiation forecast lowHigh"
                                                            stroke="none"
                                                            fill="url(#soilRadArea)"
                                                            connectNulls
                                                            dot={false}
                                                            activeDot={false}
                                                        />
                                                        <Line type="monotone" dataKey="Solar Radiation forecast low" connectNulls={true} stroke={GRAPH_COLORS.SOLAR_RADIATION.LOW} strokeWidth={3} strokeDasharray={"5 5"} dot={false} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Solar Radiation forecast high" connectNulls={true} stroke={GRAPH_COLORS.SOLAR_RADIATION.HIGH} strokeWidth={3} strokeDasharray={"2 2"} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Solar Radiation" connectNulls={true} stroke={GRAPH_COLORS.SOLAR_RADIATION.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="ndvi" stroke={GRAPH_COLORS.NDVI.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div> */}


                                        {/* <div className='mb-5'>
                                            <h2 className='text-xl font-semibold'>Wind Direction,  {getLatestvalue('Wind Direction')} deg</h2>
                                            <div className='w-full h-[360px] relative'>
                                                {!graphData &&
                                                    <div className='absolute flex items-center justify-center w-100 h-100'><Loader /></div>
                                                }
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <ComposedChart
                                                        width={500}
                                                        height={300}
                                                        data={graphData}
                                                        margin={{ top: 5, right: 25, left: 0, bottom: 30, }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="windDirectionArea" x1="0%" x2="0%" y1="0%" y2="100%">
                                                                <stop offset="0%" stopColor={GRAPH_COLORS.WIND_DIRECTION.LOW} />
                                                                <stop offset="100%" stopColor={GRAPH_COLORS.WIND_DIRECTION.HIGH} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
                                                        <YAxis domain={['dataMin', 'dataMax ']} tick={<CustomizedYAxisTick />} />
                                                        
                                                        <Tooltip
                                                            contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                                            labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                                            itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                                                            labelFormatter={(value: any) => moment(value).format('DD-MMM')}
                                                        />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="Wind Direction forecast lowHigh"
                                                            stroke="none"
                                                            fill="url(#windDirectionArea)"
                                                            connectNulls
                                                            dot={false}
                                                            activeDot={false}
                                                        />
                                                        <Line type="monotone" dataKey="Wind Direction forecast low" connectNulls={true} stroke={GRAPH_COLORS.WIND_DIRECTION.LOW} strokeWidth={3} strokeDasharray={"5 5"} dot={false} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Wind Direction forecast high" connectNulls={true} stroke={GRAPH_COLORS.WIND_DIRECTION.HIGH} strokeWidth={3} strokeDasharray={"2 2"} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Wind Direction" connectNulls={true} stroke={GRAPH_COLORS.WIND_DIRECTION.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        
                                                        <Line type="monotone" dataKey="ndvi" stroke={GRAPH_COLORS.NDVI.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        
                                                        <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div> */}

                                        {/* <div className='mb-5'>
                                            <h2 className='text-xl font-semibold'>Wind Speed,  {getLatestvalue('Wind speed')} km/h</h2>
                                            <div className='w-full h-[360px] relative'>
                                                {!graphData &&
                                                    <div className='absolute flex items-center justify-center w-100 h-100'><Loader /></div>
                                                }
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <ComposedChart
                                                        width={500}
                                                        height={300}
                                                        data={graphData}
                                                        margin={{ top: 5, right: 25, left: 0, bottom: 30, }}
                                                    >
                                                        <defs>
                                                            <linearGradient id="windSpeedArea" x1="0%" x2="0%" y1="0%" y2="100%">
                                                                <stop offset="0%" stopColor={GRAPH_COLORS.WIND_SPEED.LOW} />
                                                                <stop offset="100%" stopColor={GRAPH_COLORS.WIND_SPEED.HIGH} />
                                                            </linearGradient>
                                                        </defs>
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <XAxis dataKey="date" tick={<CustomizedXAxisTick />} />
                                                        <YAxis domain={['dataMin', 'dataMax ']} tick={<CustomizedYAxisTick />} />
                                                        <Tooltip
                                                            contentStyle={{ backgroundColor: '#f4f4f4', border: '0px', borderRadius: '5px', textAlign: 'left' }}
                                                            labelStyle={{ color: '#222222', fontSize: '13px', textAlign: 'left', fontWeight: '700', lineHeight: 1.6 }}
                                                            itemStyle={{ fontSize: '12px', textAlign: 'left', padding: 0, lineHeight: 1.3, fontWeight: '500' }}
                                                            labelFormatter={(value: any) => moment(value).format('DD-MMM')}
                                                        />
                                                        <Area
                                                            type="monotone"
                                                            dataKey="Wind speed forecast lowHigh"
                                                            stroke="none"
                                                            fill="url(#windSpeedArea)"
                                                            connectNulls
                                                            dot={false}
                                                            activeDot={false}
                                                        />
                                                        <Line type="monotone" dataKey="Wind speed forecast low" connectNulls={true} stroke={GRAPH_COLORS.WIND_SPEED.LOW} strokeWidth={3} strokeDasharray={"5 5"} dot={false} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Wind speed forecast high" connectNulls={true} stroke={GRAPH_COLORS.WIND_SPEED.HIGH} strokeWidth={3} strokeDasharray={"2 2"} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="Wind speed" connectNulls={true} stroke={GRAPH_COLORS.WIND_SPEED.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Line type="monotone" dataKey="ndvi" stroke={GRAPH_COLORS.NDVI.PRIMARY} strokeWidth={3} dot={<CustomizedDot />} activeDot={{ r: 4 }} />
                                                        <Legend iconType="plainline" align="center" verticalAlign='top' height={30} iconSize={10} content={renderLegend} />

                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </div> */}


                                    </div>
                                </div>
                                <RightPanel url={URLS.FARMS.ANALYTICS} page='analytics' fields={fields} field={field} backUrl={replaceUrlParams(URLS.FARMS.WEATHER, { id: params.id })} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Analytics;